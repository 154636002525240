import React, { useEffect, useState } from 'react'
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  ThemeProvider,
  createTheme,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../Store/Hooks'
import { showAlert } from '../../../../Store/Slice/alert.slice'
import {
  createMasterByKey,
  updateMasterByKey,
} from '../../../../Store/Slice/system-configuration.slice'
import { MUIBox, MUIButton } from '../../../Shared'
import { setLoader } from '../../../../Store/Slice/loader.slice'
import mainApiService from '../../../../Services'
import { MASTERS_MANAGEMENT } from '../../../../Constants/ApiDefinations'
import CONSTANTS, { PAGES_ROUTES } from '../../../../Constants'
import * as yup from 'yup'
import { convertHyphenatedString, getMasterPayload, handleResponse } from '../../../../Helpers/Util'
import { selectMasterData } from '../../../../Store/Slice/masters.slice'
interface SystemConfigurationFormPropsInterface {
  mastersPageType: string
  masterObj: string
  mastersId?: string
  editSystemConfig?: any
}
const segmentFieldConfig: any = {
  country: [
    { label: 'Key', name: 'key', type: 'text', required: true },
    { label: 'Value', name: 'value', type: 'text', required: true },
    {
      label: 'Status',
      name: 'status',
      type: 'select',
      options: CONSTANTS.ROLE_STATUS,
      required: true,
    },
  ],
  'ab-segment': [
    { label: 'Key', name: 'key', type: 'text', required: true },
    { label: 'Value', name: 'value', type: 'text', required: true },
    {
      label: 'Status',
      name: 'status',
      type: 'select',
      options: CONSTANTS.ROLE_STATUS,
      required: true,
    },
  ],
  'publishing-segment': [
    { label: 'Key', name: 'key', type: 'text', required: true },
    { label: 'Value', name: 'value', type: 'text', required: true },
    {
      label: 'Status',
      name: 'status',
      type: 'select',
      options: CONSTANTS.ROLE_STATUS,
      required: true,
    },
  ],
  platform: [
    { label: 'Value', name: 'value', type: 'text', required: true },
    {
      label: 'Status',
      name: 'status',
      type: 'select',
      options: CONSTANTS.ROLE_STATUS,
      required: true,
    },
  ],
  cluster: [
    { label: 'Value', name: 'value', type: 'text', required: true },
    {
      label: 'Status',
      name: 'status',
      type: 'select',
      options: CONSTANTS.ROLE_STATUS,
      required: true,
    },
  ],
  segment: [
    { label: 'Value', name: 'value', type: 'text', required: true },
    {
      label: 'Status',
      name: 'status',
      type: 'select',
      options: CONSTANTS.ROLE_STATUS,
      required: true,
    },
  ],
}
const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: '#D4351C' },
      },
    },
  },
})
const SystemConfigurationForm = ({
  mastersPageType,
  masterObj,
  mastersId,
  editSystemConfig,
}: SystemConfigurationFormPropsInterface) => {
  const SegmentMasterData = useAppSelector(selectMasterData)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const segmentFields = segmentFieldConfig[masterObj] || [
    { label: 'Key', name: 'key', type: 'text', required: true },
    { label: 'Value', name: 'value', type: 'text', required: true },
  ]
  const initialFormState = segmentFields?.reduce((acc: any, field: any) => {
    acc[field.name] = editSystemConfig ? editSystemConfig[field.name] : ''
    return acc
  }, {})
  initialFormState.status = editSystemConfig?.status || 'ACTIVE'
  const [formValues, setFormValues] = useState(initialFormState)
  const [formErrors, setFormErrors] = useState<any>({})
  useEffect(() => {
    if (mastersPageType === CONSTANTS.PAGE_TYPE.EDIT && editSystemConfig) {
      setFormValues({
        ...editSystemConfig,
        status: editSystemConfig.status || 'ACTIVE',
      })
    }
  }, [editSystemConfig, mastersPageType])
  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
    if (formErrors?.[name]) {
      validateField(name, value)
    }
  }
  const validateField = (name: string, value: string) => {
    let error = ''
    if (!value.trim()) {
      error = `${name.charAt(0).toUpperCase() + name.slice(1)} field is required.`
    }
    setFormErrors((prevErrors: any) => ({ ...prevErrors, [name]: error }))
  }
  const validateForm = () => {
    const errors: any = {}
    segmentFields.forEach((field: any) => {
      if (field.required && !formValues[field.name].trim()) {
        errors[field.name] = `${field.label} field is required.`
      }
    })
    setFormErrors(errors)
    return Object.keys(errors).length === 0
  }
  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    if (!validateForm()) {
      return
    }
    try {
      let result: any
      if (mastersPageType === CONSTANTS.PAGE_TYPE.CREATE) {
        if (SegmentMasterData?.includes(masterObj.toLowerCase())) {
          dispatch(setLoader(true))
          const requestedData: any = MASTERS_MANAGEMENT.SEGMENT_MASTER.CREATE(masterObj, formValues)
          result = await mainApiService(requestedData)
          dispatch(setLoader(false))
        } else {
          const payload = getMasterPayload(masterObj.replace(/-/g, '_').toUpperCase(), formValues)
          result = await dispatch(createMasterByKey(payload) as any)
        }
      }
      if (mastersPageType === CONSTANTS.PAGE_TYPE.EDIT && mastersId) {
        if (SegmentMasterData?.includes(masterObj.toLowerCase())) {
          dispatch(setLoader(true))
          const editData = { ...formValues, [convertHyphenatedString(masterObj) + 'Id']: mastersId }
          const requestedData: any = MASTERS_MANAGEMENT.SEGMENT_MASTER.UPDATE(masterObj, editData)
          result = await mainApiService(requestedData)
          dispatch(setLoader(false))
        } else {
          let payload: any = getMasterPayload(
            masterObj.replace(/-/g, '_').toUpperCase(),
            formValues,
          )
          payload = {
            ...payload,
            systemConfigId: mastersId,
          }
          result = await dispatch(updateMasterByKey(payload) as any)
        }
      }
      const { error, message } = handleResponse(result)
      if (error) {
        dispatch(showAlert([true, message, 'error']))
        return
      }
      dispatch(showAlert([true, message, 'success']))
      navigate(`/${PAGES_ROUTES.BoltMaster}/${PAGES_ROUTES.Masters}/${masterObj}`)
    } catch (validationErrors) {
      const errors = (validationErrors as yup.ValidationError)?.inner?.reduce(
        (acc: any, error: yup.ValidationError) => {
          acc[error.path!] = error.message
          return acc
        },
        {},
      )
      setFormErrors(errors)
    }
  }
  const renderFormFields = () => {
    return segmentFields.map((field: any, index: number) => (
      <Grid item xs={6} key={`${field.name}-${field.label}`}>
        {field.type === 'text' && (
          <FormControl fullWidth error={Boolean(formErrors?.[field.name])}>
            <TextField
              fullWidth
              label={
                <span>
                  {field.label}
                  {field.required && <span style={{ color: '#D4351C' }}>*</span>}
                </span>
              }
              name={field.name}
              type='text'
              value={formValues?.[field.name]}
              onChange={handleInputChange}
              error={Boolean(formErrors?.[field.name])}
              helperText={formErrors?.[field.name]}
              inputProps={{ maxLength: 100 }}
            />
          </FormControl>
        )}
        {field.type === 'select' && (
          <FormControl fullWidth error={Boolean(formErrors?.[field.name])}>
            <InputLabel>{field.label}</InputLabel>
            <Select
              name={field.name}
              value={formValues?.[field.name]}
              onChange={handleInputChange}
              label={
                <span>
                  {field.label}
                  {field.required && <span style={{ color: '#D4351C' }}>*</span>}
                </span>
              }
              sx={{ textAlign: 'left' }}
            >
              {field?.options?.map((option: any, idx: number) => (
                <MenuItem key={`${option.value}-${option.label}`} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{formErrors?.[field.name] || ''}</FormHelperText>
          </FormControl>
        )}
      </Grid>
    ))
  }
  return (
    <MUIBox>
      <div className='heading-6 d-flex  rolebox__header-padding' style={{ padding: 12 }}>
        {masterObj?.split('-')?.join(' ')}
      </div>
      <div className={'hr'}></div>
      <ThemeProvider theme={theme}>
        <form onSubmit={onSubmit}>
          <Grid padding={2} container spacing={3} columnSpacing={10} rowSpacing={4}>
            {renderFormFields()}
            <Grid item xs={12}>
              <MUIButton
                className='c-btn'
                type='submit'
                variant='contained'
                label={mastersPageType === CONSTANTS.PAGE_TYPE.CREATE ? 'Create' : 'Update'}
              />
            </Grid>
          </Grid>
        </form>
      </ThemeProvider>
    </MUIBox>
  )
}
export default SystemConfigurationForm
