import React, { Suspense } from 'react'
import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom'
import RoleUsers from '../Components/MangeRole/RoleEditor/RoleUsers'
import Loader from '../Components/Shared/Loader'
import { PAGES_ROUTES } from '../Constants/PagesRoutes'
import DefaultLayout from '../Layouts/DefaultLayout'
import Home from '../Pages/Home'
import CreateUpdateParameter from '../Pages/ParameterRepository/CreateUpdateParameter'
import MastersList from '../Pages/Masters'
import CountriesAddEdit from '../Pages/Masters/Countries/CountriesAddEdit'
import { authenticationGuard } from './auth'
import PlainLayout from '../Layouts/PlainLayout'
import { MarkAsDoneProvider } from '../Context/MarkAsDoneProvider'
import { TipsManage } from '../Pages/ManageTips'
import CreateUpdateTips from '../Pages/ManageTips/CreateUpdateTips'
import SavedSearchListing from '../Pages/AdvancedSearch/SavedSearchList'
import AssociatedItems from '../Components/Molecule/AssociatedItems'
const NotFound = React.lazy(() => import('../Pages/Erros/404'))
const ErrorFound = React.lazy(() => import('../Pages/Erros/500'))
const AccessDeniedComponent = React.lazy(() => import('../Pages/AccessDenied'))
const ComingSoonComponent = React.lazy(() => import('../Pages/ComingSoon'))
const InternalServerErrorComponent = React.lazy(() => import('../Pages/InternalServerError'))
const Login = React.lazy(() => import('../Pages/Login'))
const AuthCallback = React.lazy(() => import('../Pages/Auth'))
const CreateUpdateRole = React.lazy(() => import('../Pages/ManageRole/CreateUpdateRole'))
const CreateUpdateUser = React.lazy(() => import('../Pages/ManageUser/CreateUpdateUser'))
const RoleListing = React.lazy(() => import('../Pages/ManageRole/RolesListing'))
const UserListing = React.lazy(() => import('../Pages/ManageUser/UserListing'))
const WidgetManage = React.lazy(() => import('../Pages/ManageWidget/WidgetManage'))
const ParameterListing = React.lazy(() => import('../Pages/ParameterRepository/ParameterListing'))
const SegmentListing = React.lazy(() => import('../Pages/ManageSegment/SegmentListing'))
const CreateUpdateSegment = React.lazy(() => import('../Pages/ManageSegment/CreateUpdateSegment'))

const CountriesListComponent = React.lazy(() => import('../Pages/Masters/Countries/CountriesList'))
const SystemConfigurationList = React.lazy(
  () => import('../Pages/Masters/SystemConfiguration/List'),
)
const SystemConfigurationEdit = React.lazy(
  () => import('../Pages/Masters/SystemConfiguration/Edit'),
)

// Manage Widget componen render with lazy loading
const ManageWidgetEditorComponent = React.lazy(() => import('../Pages/ManageWidget/Editor'))

// container definition component render with lazy loading
const ContainerDefinitionListComponent = React.lazy(
  () => import('../Pages/ContainerDefinitionModule/List'),
)
const ContainerDefinitionEditorComponent = React.lazy(
  () => import('../Pages/ContainerDefinitionModule/Editor'),
)

// widget definition component render with lazy loading
const WidgetDefinitionListComponent = React.lazy(
  () => import('../Pages/WidgetDefinitionModule/List'),
)
const WidgetDefinitionEditorComponent = React.lazy(
  () => import('../Pages/WidgetDefinitionModule/Editor'),
)

const ContainerListing = React.lazy(() => import('../Pages/ManageContainer'))
const ChildContainerListing = React.lazy(
  () => import('../Pages/ManageContainer/ChildContainerListing'),
)
const CreateUpdateContainer = React.lazy(() => import('../Components/ManageContainer'))

const SiteNavigationListing = React.lazy(() => import('../Pages/SiteNavigation'))
const CreateUpdateSiteNavigation = React.lazy(() => import('../Components/SiteNavigation'))

const AuditList = React.lazy(() => import('../Pages/AuditLogs/AuditList'))
const GlobalSearch = React.lazy(() => import('../Pages/GlobalSearch'))
const AdvancedSearchPage = React.lazy(() => import('../Pages/AdvancedSearch'))
const AuditDetails = React.lazy(() => import('../Pages/AuditLogs/AuditDetails'))
const ItemLogs = React.lazy(() => import('../Pages/AuditLogs/ItemLogs'))
const ContentStatusPage = React.lazy(() => import('../Pages/ContentStatus'))

const {
  LoginPage,
  AuthValidatePage,
  ErrorPage,
  RootPage,
  HomePage,
  ManageUser,
  BoltMaster,
  RolesPage,
  CreateRolePage,
  ViewEditRolePage,
  EditRoleId,
  SavedSearch,
  id,
  parameterId,
  userId,
  AccessDenied,
  ComingSoon,
  InternalServerError,
  Masters,
  Countries,
  ViewEditCountriesPage,
  CountriesId,
  SystemConfigurationPageType,
  SystemConfigurationId,
  PageType,
  ParameterRepository,
  ParameterCreate,
  ViewEditParameterPage,
  ManageSegment,
  segmentId,
  ManageWidget,
  EditWidgetID,
  ManageContainer,
  containerId,
  SiteNavigation,
  siteNavigationId,
  globalSearch,
  AdvancedSearch,
  ViewAuditLog,
  ItemAuditLog,
  ItemAuditLogListing,
  ChildContainer,
  ManageTips,
  TipsId,
  AssociatedItem,
  Module,
  ContentStatus,
} = PAGES_ROUTES

export const routes: RouteObject[] = [
  {
    path: LoginPage,
    element: (
      <Suspense fallback={<Loader />}>
        <Login />
      </Suspense>
    ),
    errorElement: <ErrorFound />,
  },
  {
    path: AuthValidatePage,
    element: (
      <Suspense fallback={<Loader />}>
        <AuthCallback />
      </Suspense>
    ),
    errorElement: <ErrorFound />,
  },
  {
    path: ErrorPage,
    element: (
      <Suspense fallback={<Loader />}>
        <InternalServerErrorComponent />
      </Suspense>
    ),
  },
  {
    path: RootPage,
    element: <DefaultLayout />,
    errorElement: <ErrorFound />,
    children: [
      {
        path: HomePage,
        element: <Home />,
        loader: authenticationGuard,
      },
      {
        path: RolesPage,
        element: <RoleListing />,
        loader: authenticationGuard,
      },
      {
        path: `${RolesPage}/${CreateRolePage}`,
        element: <CreateUpdateRole />,
        loader: authenticationGuard,
      },
      {
        path: ManageUser,
        element: <UserListing />,
        loader: authenticationGuard,
      },
      {
        path: ManageWidget,
        element: <WidgetManage />,
        loader: authenticationGuard,
      },
      {
        path: ManageContainer,
        element: <ContainerListing />,
        loader: authenticationGuard,
      },
      {
        path: `${ManageContainer}/${ChildContainer}/${containerId}`,
        element: <ChildContainerListing />,
        loader: authenticationGuard,
      },
      {
        path: SiteNavigation,
        element: <SiteNavigationListing />,
        loader: authenticationGuard,
      },
      {
        path: `${RolesPage}/${ViewEditRolePage}/${EditRoleId}`,
        element: <CreateUpdateRole />,
        loader: authenticationGuard,
      },
      {
        path: `${BoltMaster}/${ParameterRepository}`,
        element: <ParameterListing />,
        loader: authenticationGuard,
      },
      {
        path: `${BoltMaster}/${ParameterRepository}/${ParameterCreate}`,
        element: <CreateUpdateParameter />,
        loader: authenticationGuard,
      },
      {
        path: `${BoltMaster}/${ParameterRepository}/${ViewEditParameterPage}/${parameterId}`,
        element: <CreateUpdateParameter />,
        loader: authenticationGuard,
      },

      {
        path: `${RolesPage}/${ViewEditRolePage}/${EditRoleId}/users`,
        element: <RoleUsers />,
        loader: authenticationGuard,
      },

      {
        path: PAGES_ROUTES.UsersPage,
        element: <UserListing />,
        loader: authenticationGuard,
      },
      {
        path: ManageUser + '/' + id + '/' + userId,
        element: <CreateUpdateUser />,
        loader: authenticationGuard,
      },
      {
        path: ManageUser + '/' + id,
        element: <CreateUpdateUser />,
        loader: authenticationGuard,
      },
      {
        path: PAGES_ROUTES.ManageUser,
        element: <CreateUpdateUser />,
      },
      {
        path: ManageSegment,
        element: <SegmentListing />,
        loader: authenticationGuard,
      },
      {
        path: ManageSegment + '/' + id + '/' + segmentId,
        element: <CreateUpdateSegment />,
        loader: authenticationGuard,
      },
      {
        path: ManageSegment + '/' + id,
        element: <CreateUpdateSegment />,
        loader: authenticationGuard,
      },
      {
        path: AccessDenied,
        element: <AccessDeniedComponent />,
      },
      {
        path: ComingSoon,
        element: <ComingSoonComponent />,
      },
      {
        path: InternalServerError,
        element: <InternalServerErrorComponent />,
      },
      {
        path: '/',
        element: <Navigate to='/home' replace />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
      {
        path: `${BoltMaster}/${Masters}`,
        element: <MastersList />,
        loader: authenticationGuard,
      },
      {
        path: `${BoltMaster}/${ManageTips}`,
        element: <TipsManage />,
        loader: authenticationGuard,
      },
      {
        path: `${BoltMaster}/${ManageTips}/${id}`,
        element: <CreateUpdateTips />,
        loader: authenticationGuard,
      },
      {
        path: `${BoltMaster}/${ManageTips}/${id}/${TipsId}`,
        element: <CreateUpdateTips />,
        loader: authenticationGuard,
      },
      {
        path: `${BoltMaster}/${Masters}/${Countries}`,
        element: <CountriesListComponent />,
        loader: authenticationGuard,
      },
      {
        path: `${BoltMaster}/${Masters}/${Countries}/${ViewEditCountriesPage}/${CountriesId}`,
        element: <CountriesAddEdit />,
        loader: authenticationGuard,
      },
      {
        path: `${BoltMaster}/${Masters}/${Countries}/${ViewEditCountriesPage}`,
        element: <CountriesAddEdit />,
        loader: authenticationGuard,
      },
      {
        path: `${BoltMaster}/${Masters}/${SystemConfigurationPageType}`,
        element: <SystemConfigurationList />,
        loader: authenticationGuard,
      },
      {
        path: `${BoltMaster}/${Masters}/${SystemConfigurationPageType}/${PageType}`,
        element: <SystemConfigurationEdit />,
        loader: authenticationGuard,
      },
      {
        path: `${BoltMaster}/${Masters}/${SystemConfigurationPageType}/${PageType}/${SystemConfigurationId}`,
        element: <SystemConfigurationEdit />,
        loader: authenticationGuard,
      },
      {
        path: `${PAGES_ROUTES.BoltMasterNew}/${PAGES_ROUTES.ContainerDefinitionList}`,
        element: <ContainerDefinitionListComponent />,
        loader: authenticationGuard,
      },
      {
        path: `${PAGES_ROUTES.BoltMasterNew}/${PAGES_ROUTES.ContainerDefinitionList}/${PAGES_ROUTES.PageType}`,
        element: <ContainerDefinitionEditorComponent />,
        loader: authenticationGuard,
      },
      {
        path: `${PAGES_ROUTES.BoltMasterNew}/${PAGES_ROUTES.ContainerDefinitionList}/${PAGES_ROUTES.PageType}/${PAGES_ROUTES.ContainerDefinitionId}`,
        element: <ContainerDefinitionEditorComponent />,
        loader: authenticationGuard,
      },
      {
        path: `${PAGES_ROUTES.BoltMasterNew}/${PAGES_ROUTES.WidgetDefinitionList}`,
        element: <WidgetDefinitionListComponent />,
        loader: authenticationGuard,
      },
      {
        path: `${PAGES_ROUTES.BoltMasterNew}/${PAGES_ROUTES.WidgetDefinitionList}/${PAGES_ROUTES.PageType}`,
        element: <WidgetDefinitionEditorComponent />,
        loader: authenticationGuard,
      },
      {
        path: `${PAGES_ROUTES.BoltMasterNew}/${PAGES_ROUTES.WidgetDefinitionList}/${PAGES_ROUTES.PageType}/${PAGES_ROUTES.WidgetDefinitionId}`,
        element: <WidgetDefinitionEditorComponent />,
        loader: authenticationGuard,
      },
      {
        path: ItemAuditLog,
        element: <ItemLogs />,
        loader: authenticationGuard,
      },
      {
        path: ContentStatus,
        element: <ContentStatusPage />,
        loader: authenticationGuard,
      },
    ],
  },
  {
    path: RootPage,
    element: (
      <MarkAsDoneProvider>
        <PlainLayout />
      </MarkAsDoneProvider>
    ),
    errorElement: <ErrorFound />,
    children: [
      {
        path: `${ManageWidget}/${PageType}/${EditWidgetID}`,
        element: <ManageWidgetEditorComponent />,
        loader: authenticationGuard,
      },
      {
        path: `${ManageContainer}/${PageType}/${containerId}`,
        element: <CreateUpdateContainer />,
        loader: authenticationGuard,
      },
      {
        path: `${SiteNavigation}/${PageType}/${siteNavigationId}`,
        element: <CreateUpdateSiteNavigation />,
        loader: authenticationGuard,
      },
      {
        path: ItemAuditLogListing,
        element: <AuditList />,
        loader: authenticationGuard,
      },
      {
        path: ViewAuditLog,
        element: <AuditDetails />,
        loader: authenticationGuard,
      },
      {
        path: globalSearch,
        element: <GlobalSearch />,
        loader: authenticationGuard,
      },
      {
        path: AdvancedSearch,
        element: <AdvancedSearchPage />,
        loader: authenticationGuard,
      },
      {
        path: `${AdvancedSearch}/${id}`,
        element: <AdvancedSearchPage />,
        loader: authenticationGuard,
      },
      {
        path: `${AdvancedSearch + '/' + SavedSearch}`,
        element: <SavedSearchListing />,
        loader: authenticationGuard,
      },
      {
        path: `${AssociatedItem}/${Module}/${id}`,
        element: <AssociatedItems />,
        loader: authenticationGuard,
      },
    ],
  },
]

const router = createBrowserRouter(routes)

export default router
