const ProgressBarIcon = () => {
  return (
    <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.2569 12.9635C21.1709 12.9635 21.0884 12.9293 21.0276 12.8685C20.9668 12.8076 20.9326 12.7251 20.9326 12.6391V11.6759C20.9326 11.5899 20.9668 11.5074 21.0276 11.4466C21.0884 11.3857 21.1709 11.3516 21.2569 11.3516C21.343 11.3516 21.4255 11.3857 21.4863 11.4466C21.5471 11.5074 21.5813 11.5899 21.5813 11.6759V12.6391C21.5813 12.7251 21.5471 12.8076 21.4863 12.8685C21.4255 12.9293 21.343 12.9635 21.2569 12.9635Z'
        fill='#402080'
      />
      <path
        d='M21.2569 9.72915C21.1709 9.72915 21.0884 9.69498 21.0276 9.63416C20.9668 9.57334 20.9326 9.49084 20.9326 9.40483V8.46104C20.9326 8.37503 20.9668 8.29253 21.0276 8.23171C21.0884 8.17089 21.1709 8.13672 21.2569 8.13672C21.343 8.13672 21.4255 8.17089 21.4863 8.23171C21.5471 8.29253 21.5813 8.37503 21.5813 8.46104V9.40483C21.5813 9.49084 21.5471 9.57334 21.4863 9.63416C21.4255 9.69498 21.343 9.72915 21.2569 9.72915Z'
        fill='#402080'
      />
      <path
        d='M21.2567 23.3511H4.06749C3.98147 23.3511 3.89898 23.317 3.83816 23.2561C3.77733 23.1953 3.74316 23.1128 3.74316 23.0268V0.972762C3.74316 0.886746 3.77733 0.804252 3.83816 0.74343C3.89898 0.682607 3.98147 0.648438 4.06749 0.648438H21.2567C21.3427 0.648438 21.4252 0.682607 21.486 0.74343C21.5468 0.804252 21.581 0.886746 21.581 0.972762V6.19114C21.581 6.27716 21.5468 6.35965 21.486 6.42047C21.4252 6.4813 21.3427 6.51546 21.2567 6.51546C21.1707 6.51546 21.0882 6.4813 21.0273 6.42047C20.9665 6.35965 20.9324 6.27716 20.9324 6.19114V1.29709H4.39181V22.7025H20.9324V14.909C20.9324 14.823 20.9665 14.7405 21.0273 14.6796C21.0882 14.6188 21.1707 14.5847 21.2567 14.5847C21.3427 14.5847 21.4252 14.6188 21.486 14.6796C21.5468 14.7405 21.581 14.823 21.581 14.909V23.0268C21.581 23.1128 21.5468 23.1953 21.486 23.2561C21.4252 23.317 21.3427 23.3511 21.2567 23.3511Z'
        fill='#402080'
      />
      <path
        d='M19.0943 5.51388H10.8341C10.7481 5.51388 10.6656 5.47971 10.6048 5.41889C10.5439 5.35807 10.5098 5.27557 10.5098 5.18956C10.5098 5.10354 10.5439 5.02105 10.6048 4.96023C10.6656 4.8994 10.7481 4.86523 10.8341 4.86523H19.0943C19.1803 4.86523 19.2628 4.8994 19.3236 4.96023C19.3845 5.02105 19.4186 5.10354 19.4186 5.18956C19.4186 5.27557 19.3845 5.35807 19.3236 5.41889C19.2628 5.47971 19.1803 5.51388 19.0943 5.51388Z'
        fill='#402080'
      />
      <path
        d='M19.0943 10.0549H10.8341C10.7481 10.0549 10.6656 10.0207 10.6048 9.95991C10.5439 9.89908 10.5098 9.81659 10.5098 9.73057C10.5098 9.64456 10.5439 9.56206 10.6048 9.50124C10.6656 9.44042 10.7481 9.40625 10.8341 9.40625H19.0943C19.1803 9.40625 19.2628 9.44042 19.3236 9.50124C19.3845 9.56206 19.4186 9.64456 19.4186 9.73057C19.4186 9.81659 19.3845 9.89908 19.3236 9.95991C19.2628 10.0207 19.1803 10.0549 19.0943 10.0549Z'
        fill='#402080'
      />
      <path
        d='M19.0943 14.594H10.8341C10.7481 14.594 10.6656 14.5598 10.6048 14.499C10.5439 14.4381 10.5098 14.3557 10.5098 14.2696C10.5098 14.1836 10.5439 14.1011 10.6048 14.0403C10.6656 13.9795 10.7481 13.9453 10.8341 13.9453H19.0943C19.1803 13.9453 19.2628 13.9795 19.3236 14.0403C19.3845 14.1011 19.4186 14.1836 19.4186 14.2696C19.4186 14.3557 19.3845 14.4381 19.3236 14.499C19.2628 14.5598 19.1803 14.594 19.0943 14.594Z'
        fill='#402080'
      />
      <path
        d='M19.0943 19.135H10.8341C10.7481 19.135 10.6656 19.1008 10.6048 19.04C10.5439 18.9792 10.5098 18.8967 10.5098 18.8107C10.5098 18.7246 10.5439 18.6421 10.6048 18.5813C10.6656 18.5205 10.7481 18.4863 10.8341 18.4863H19.0943C19.1803 18.4863 19.2628 18.5205 19.3236 18.5813C19.3845 18.6421 19.4186 18.7246 19.4186 18.8107C19.4186 18.8967 19.3845 18.9792 19.3236 19.04C19.2628 19.1008 19.1803 19.135 19.0943 19.135Z'
        fill='#402080'
      />
      <path
        d='M8.56412 20.24C8.47811 20.24 8.39563 20.2058 8.33482 20.145L6.20823 18.0164C6.14915 17.9553 6.11646 17.8733 6.1172 17.7883C6.11794 17.7033 6.15205 17.6219 6.21218 17.5618C6.27231 17.5017 6.35366 17.4675 6.43869 17.4668C6.52373 17.4661 6.60566 17.4988 6.66682 17.5578L8.79342 19.6861C8.83876 19.7314 8.86964 19.7892 8.88215 19.8521C8.89466 19.915 8.88823 19.9802 8.86369 20.0395C8.83915 20.0987 8.79759 20.1494 8.74427 20.185C8.69095 20.2206 8.62826 20.24 8.56412 20.24Z'
        fill='#402080'
      />
      <path
        d='M6.4356 20.2403C6.37159 20.2399 6.30913 20.2206 6.25608 20.1848C6.20304 20.1489 6.16178 20.0982 6.13751 20.039C6.11323 19.9798 6.10703 19.9147 6.11967 19.8519C6.13232 19.7892 6.16325 19.7316 6.20857 19.6864L8.33679 17.5578C8.39795 17.4988 8.47988 17.4661 8.56492 17.4668C8.64995 17.4675 8.7313 17.5017 8.79143 17.5618C8.85156 17.6219 8.88567 17.7033 8.88641 17.7883C8.88715 17.8733 8.85446 17.9553 8.79538 18.0164L6.66716 20.145C6.63681 20.1754 6.60071 20.1995 6.56096 20.2159C6.5212 20.2323 6.47859 20.2406 6.4356 20.2403Z'
        fill='#402080'
      />
      <path
        d='M7.1266 6.7012C7.04059 6.70118 6.95811 6.667 6.8973 6.60617L6.00022 5.70876C5.94114 5.6476 5.90845 5.56567 5.90919 5.48063C5.90993 5.3956 5.94404 5.31425 6.00417 5.25412C6.0643 5.19399 6.14565 5.15988 6.23069 5.15914C6.31572 5.1584 6.39765 5.19109 6.45882 5.25017L7.1266 5.91763L9.1893 3.8546C9.25025 3.79379 9.33285 3.75967 9.41894 3.75977C9.50504 3.75986 9.58757 3.79415 9.64838 3.85509C9.7092 3.91603 9.74331 3.99863 9.74322 4.08473C9.74313 4.17082 9.70884 4.25336 9.6479 4.31417L7.3559 6.60617C7.29509 6.667 7.21261 6.70118 7.1266 6.7012Z'
        fill='#402080'
      />
      <path
        d='M7.1266 11.2403C7.04059 11.2402 6.95811 11.2061 6.8973 11.1452L6.00022 10.2478C5.94114 10.1867 5.90845 10.1047 5.90919 10.0197C5.90993 9.93466 5.94404 9.85331 6.00417 9.79318C6.0643 9.73305 6.14565 9.69894 6.23069 9.6982C6.31572 9.69746 6.39765 9.73015 6.45882 9.78923L7.1266 10.4567L9.1893 8.39366C9.21948 8.36355 9.25529 8.33968 9.29469 8.32341C9.33409 8.30714 9.37631 8.29878 9.41894 8.29883C9.46157 8.29887 9.50378 8.30732 9.54314 8.32367C9.58251 8.34003 9.61827 8.36398 9.64838 8.39415C9.6785 8.42433 9.70237 8.46014 9.71864 8.49954C9.73491 8.53894 9.74326 8.58116 9.74322 8.62379C9.74317 8.66642 9.73473 8.70862 9.71838 8.74799C9.70202 8.78736 9.67807 8.82312 9.6479 8.85323L7.3559 11.1452C7.29509 11.2061 7.21261 11.2402 7.1266 11.2403Z'
        fill='#402080'
      />
      <path
        d='M7.12676 15.7813C7.04075 15.7813 6.95827 15.7471 6.89746 15.6862L6.00038 14.7888C5.9694 14.7589 5.9447 14.7231 5.9277 14.6836C5.9107 14.644 5.90175 14.6014 5.90138 14.5584C5.90101 14.5153 5.90921 14.4726 5.92552 14.4327C5.94183 14.3929 5.96591 14.3567 5.99636 14.3262C6.02681 14.2958 6.06302 14.2717 6.10288 14.2554C6.14274 14.2391 6.18545 14.2309 6.22851 14.2312C6.27157 14.2316 6.31413 14.2406 6.3537 14.2576C6.39327 14.2746 6.42906 14.2993 6.45897 14.3302L7.12676 14.9977L9.18946 12.9347C9.21964 12.9046 9.25545 12.8807 9.29485 12.8644C9.33425 12.8482 9.37647 12.8398 9.4191 12.8398C9.46173 12.8399 9.50394 12.8483 9.5433 12.8647C9.58267 12.881 9.61843 12.905 9.64854 12.9352C9.67865 12.9653 9.70253 13.0012 9.7188 13.0406C9.73507 13.08 9.74342 13.1222 9.74338 13.1648C9.74333 13.2074 9.73489 13.2496 9.71854 13.289C9.70218 13.3284 9.67823 13.3641 9.64806 13.3942L7.35606 15.6862C7.29525 15.7471 7.21277 15.7813 7.12676 15.7813Z'
        fill='#402080'
      />
    </svg>
  )
}

export default ProgressBarIcon
