import { Search } from '@mui/icons-material'
import AccountCircle from '@mui/icons-material/AccountCircle'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import MenuIcon from '@mui/icons-material/Menu'
import {
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SonyHDLogo from '../../../Assets/Images/sonyHDlogo.png'
import CONSTANTS, { PAGES_ROUTES } from '../../../Constants'
import { getLocalStorage } from '../../../Helpers/Storage'
import { logout } from '../../../Helpers/Util'
import { AppBar, StyledMenu } from '../MUI-Drawer/MUI-Drawer-Style'
import { BoltLogo } from '../MUI-Drawer/MUI-Drawer.svgIcons'
import SettingIcon from '../../Icons/SettingIcon'

const Header: React.FC<{ setOpen?: any; drawerState?: boolean; sidebar?: boolean }> = ({
  setOpen = () => {
    return
  },
  drawerState = false,
  sidebar = true,
}: {
  setOpen?: any
  drawerState?: boolean
  sidebar?: boolean
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorEl)
  const [userName, setUserName] = useState('')
  const [searchInput, setSearchInput] = useState('')

  const navigate = useNavigate()

  useEffect(() => {
    const userData = getLocalStorage(CONSTANTS.LOCAL_KEYS.USER_DATA)
    const { userName = '' } = userData
    if (userName) {
      setUserName(userName)
    }
  }, [drawerState])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleEnterKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      if (searchInput.trim() && searchInput.length > 2)
        // navigate(`/home/asset-state-details/${PAGES_ROUTES.AdvancedSearch}?search=${searchInput}`)
        navigate(`/${PAGES_ROUTES.globalSearch}?search=${searchInput}`)
    }
  }
  return (
    <AppBar position='fixed' open={drawerState} style={{ backgroundColor: '#191D23' }}>
      <Toolbar className='justify-content-between'>
        <div className='d-flex header-logo'>
          {sidebar && (
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={() => {
                setOpen(!drawerState)
              }}
              edge='start'
              sx={{
                marginRight: 2,
              }}
              data-testid='open_drawer_btn'
            >
              <MenuIcon />
            </IconButton>
          )}
          <img
            src={SonyHDLogo}
            style={{ width: '32px', height: '32px' }}
            alt='sony-logo'
            onClick={() => navigate('/home')}
            className='cursor-pointer'
            data-testid='sonyLogo_navigate_btn'
          />
          <Typography
            component='div'
            sx={{ flexGrow: 1, marginLeft: 2, cursor: 'pointer' }}
            onClick={() => navigate('/home')}
          >
            <BoltLogo />
          </Typography>
        </div>
        <div className='d-flex align-items-center'>
          <div className='advanced-search mr-2'>
            <TextField
              className='global-search-input'
              id='outlined-search'
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setSearchInput(e.target.value)
              }}
              value={searchInput}
              onKeyDown={handleEnterKeyPress}
              placeholder={'Search by title and ID'}
              InputProps={{
                id: 'searchId',
                startAdornment: (
                  <>
                    <InputAdornment position='start'>
                      <Search style={{ color: '#B1B4B6' }} />
                    </InputAdornment>
                  </>
                ),
              }}
              type='search'
            />
            <Tooltip
              title='Advance Filter'
              placement='bottom-start'
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: '12px',
                    fontWeight: '500',
                    color: '#402080',
                    bgcolor: '#ECE9F2',
                    '& .MuiTooltip-arrow': {
                      color: '#ECE9F2',
                    },
                  },
                },
              }}
              data-testid={'open_drawer_test_id'}
              sx={{
                marginRight: 2,
              }}
            >
              <div
                data-testid='advance_btn'
                className='search-advanced-btn'
                onClick={() => navigate(`/${PAGES_ROUTES.AdvancedSearch}`)}
              >
                <SettingIcon />{' '}
              </div>
            </Tooltip>
          </div>
          {!!userName && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography style={{ fontSize: '14px', fontWeight: '600' }}>{userName}</Typography>
              <IconButton
                size='large'
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                // onClick={handleMenu}
                color='inherit'
              >
                <AccountCircle />
              </IconButton>
              <span>
                <span onClick={handleClick} data-testid={'account-dropdown'}>
                  <KeyboardArrowDownIcon />
                </span>
                <StyledMenu
                  id='demo-customized-menu'
                  MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                  }}
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleClose}
                >
                  <MenuItem onClick={logout} disableRipple>
                    Logout
                  </MenuItem>
                </StyledMenu>
              </span>
            </div>
          )}
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default Header
