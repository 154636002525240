import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { useEffect, useState } from 'react'
import BulbIcon from '../../Icons/Bulb'
import styles from './MUICard.module.scss'
import { MANAGE_TIPS } from '../../../Constants/ApiDefinations'
import mainApiService from '../../../Services'
import { getSanitizedValue } from '../../../Helpers/Util'

interface MUICardTipsModal {
  tipsArr?: Array<string>
  module?: string
  pageType?: string
  page?: any // For the metacategory uses
}

/**
 * MUITipsCard Component displays a card with tips.
 *
 * @component
 * @example
 * // Example usage of MUITipsCard:
 * <MUITipsCard
 *   tipsArr={['Tip 1', 'Tip 2']}
 * />
 *
 * // Example usage of MUITipsCard:
 * <MUITipsCard
 *   module="ExampleModule"
 *   pageType="ExamplePageType"
 * />
 *
 * // Example usage of MUITipsCard:
 * <MUITipsCard
 *   module="ExampleModule"
 *   pageType="ExamplePageType"
 *   page="ExamplePage"
 * />
 *
 * @param {Object} props - The properties of the MUITipsCard component.
 * @param {Array<string>} [props.tipsArr=[]] - An array of tips to display.
 * @param {string} [props.module] - The module for which the tips are displayed.
 * @param {string} [props.pageType] - The page type for which the tips are displayed.
 * @param {string} [props.page] - The specific page for which the tips are displayed.
 *
 * @returns {React.Element} The rendered MUITipsCard component.
 */
const MUITipsCard = ({ tipsArr = [], module, pageType, page }: MUICardTipsModal) => {
  const [tooltipsData, setTooltipsData] = useState<any>('')

  useEffect(() => {
    getTipsBasedOnPages()
  }, [module, pageType, page])

  const getTipsBasedOnPages = async () => {
    const requestedData = MANAGE_TIPS.TIPS_LIST(page)
    const result: any = await mainApiService(requestedData)
    // const { responseCode } = handleResponse(result)
    if (result?.responseCode == 200) {
      const data = result?.data?.records[0]?.description
      setTooltipsData(data)
    }
  }

  return (
    tooltipsData &&
    tooltipsData.length > 0 && (
      <Card className={`${styles.tipContainer}`} sx={{ width: 250 }}>
        <CardContent className={`${styles.tipCardMain}`}>
          <div className={`${styles.cardHeader}`}>
            <BulbIcon />
            <p className={'sub-heading-4'}>Tips</p>
          </div>
          <div
            className='content-container'
            dangerouslySetInnerHTML={{ __html: getSanitizedValue(tooltipsData) }}
          ></div>
        </CardContent>
      </Card>
    )
  )
}
export default MUITipsCard
