const TickIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='23' height='23' viewBox='0 0 16 16' fill='none'>
      <path
        d='M7.05297 10.3858C6.91964 10.3858 6.79297 10.3324 6.69964 10.2391L4.81297 8.35242C4.61964 8.15909 4.61964 7.83909 4.81297 7.64576C5.0063 7.45242 5.3263 7.45242 5.51964 7.64576L7.05297 9.17909L10.4796 5.75242C10.673 5.55909 10.993 5.55909 11.1863 5.75242C11.3796 5.94576 11.3796 6.26575 11.1863 6.45909L7.4063 10.2391C7.31297 10.3324 7.1863 10.3858 7.05297 10.3858Z'
        fill='white'
      />
    </svg>
  )
}

export default TickIcon
