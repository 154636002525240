import { PAGES_ROUTES } from '.'

const { ManageContainer, ManageWidget, SiteNavigation } = PAGES_ROUTES

// Define an enum for page names
export enum PageNames {
  Root = 'Root',

  Login = 'Login',
  Home = 'Home',

  ManageContent = 'ManageContent',
  ManageContentListing = 'ManageContentListing',
  Shorts = 'Shorts',
  Movies = 'Movies',
  Sports = 'Sports',
  TvShows = 'TvShows',
  Originals = 'Originals',
  NonVODAssest = 'NonVODAssest',
  IndependentVODAssest = 'IndependentVODAssest',
  ManageContentAssetCreate = 'ManageContentAssetCreate',
  ManageContentAssetView = 'ManageContentAssetView',
  ManageContentAssetEdit = 'ManageContentAssetEdit',
  ManageContentAssetAuditRails = 'ManageContentAssetAuditRails',
  ManageContentAssetAuditRailsView = 'ManageContentAssetAuditRailsView',
  ManageContentAssetAssociatedAssets = 'ManageContentAssetAssociatedAssets',

  Role = 'Role',
  RoleCreate = 'RoleCreate',
  RoleEdit = 'RoleEdit',
  RoleView = 'RoleView',

  User = 'User',
  UserCreate = 'UserCreate',
  UserEdit = 'UserEdit',
  UserView = 'UserView',
  ManageWidget = 'ManageWidget',
  ManageContainer = 'ManageContainer',
  SiteNavigation = 'SiteNavigation',

  // MetaMaster = 'MetaMaster',
  // AttributeRepo = 'AttributeRepo',
  // AttributeRepoCreate = 'AttributeRepoCreate',
  // AttributeRepoEdit = 'AttributeRepoEdit',
  // AttributeRepoView = 'AttributeRepoView',
  // AssetsDefination = 'AssetsDefination',
  // AssetsDefinationCreate = 'AssetsDefinationCreate',
  // AssetsDefinationEdit = 'AssetsDefinationEdit',
  // AssetsDefinationView = 'AssetsDefinationView',
  // GeoPolicy = 'GeoPolicy',
  // GeoPolicyCreate = 'GeoPolicyCreate',
  // GeoPolicyEdit = 'GeoPolicyEdit',
  // GeoPolicyView = 'GeoPolicyView',
  // Masters = 'Masters',
  // MastersCreate = 'MastersCreate',
  // MastersEdit = 'MastersEdit',
  // MastersView = 'MastersView',
  // ManageTips = 'ManageTips',

  AuditLogs = 'AuditLogs',
  AuditLogsListing = 'AuditLogsListing',
  AuditLogsMaster = 'AuditLogsMaster',
  AuditLogsContent = 'AuditLogsContent',
  AuditLogsTranscoding = 'AuditLogsTranscoding',
  AuditLogsUserAndRole = 'AuditLogsUserAndRole',

  Reports = 'Reports',
  ReportsDataMigration = 'ReportsDataMigration',

  NotFound = 'NotFound',
  ErrorPage = 'ErrorPage',
  AccessDenied = 'AccessDenied',
  AuthValidate = 'AuthValidate',
  InternalServerError = 'InternalServerError',

  Segment = 'Segment',
  SegmentCreate = 'SegmentCreate',
  SegmentEdit = 'SegmentEdit',
  SegmentView = 'SegmentView',

  BoltMaster = 'BoltMaster',
  Masters = 'Masters',
  ManageTips = 'ManageTipps',
  MastersCreate = 'MastersCreate',
  MastersEdit = 'MastersEdit',
  MastersView = 'MastersView',
  ParameterRepository = 'ParameterRepository',
  ParameterRepositoryCreate = 'ParameterRepositoryCreate',
  ParameterRepositoryEdit = 'ParameterRepositoryEdit',
  ParameterRepositoryView = 'ParameterRepositoryView',

  ContainerDefinitionList = 'containerDefinitionList',
  WidgetDefinitionList = 'widgetDefinitionList',
  ContentStatus = 'ContentStatus',
}

// Define a type for page URLs
export type PageURLs = Record<PageNames, string>

// Define a type for query parameters
export type QueryParameters = Record<string, string>

// Define a type for route parameters
export type RouteParameters = Record<string, string>

// Define your page URLs
export const PAGE_URL: any = {
  [PageNames.Root]: '/',

  [PageNames.Login]: '/login',
  [PageNames.AuthValidate]: '/api/v1/sso/login',

  [PageNames.Home]: '/home',

  [PageNames.ManageContent]: '/manage-content',
  [PageNames.ManageContentListing]: '/manage-content/:contentCatagory',
  [PageNames.Shorts]: '/manage-content/shorts',
  [PageNames.Movies]: '/manage-content/movies',
  [PageNames.Sports]: '/manage-content/sports',
  [PageNames.TvShows]: '/manage-content/tv_shows',
  [PageNames.Originals]: '/manage-content/originals',
  [PageNames.NonVODAssest]: '/manage-content/non_vod_assets',
  [PageNames.IndependentVODAssest]: '/manage-content/independent_vod_assets',

  [PageNames.ManageContentAssetCreate]: '/asset/create',
  [PageNames.ManageContentAssetView]:
    '/asset/view/:contentId/:assetType/:conCategory/:assetCategory/:metaCategory',
  [PageNames.ManageContentAssetEdit]:
    '/asset/update/:contentId/:assetType/:conCategory/:assetCategory/:metaCategory',
  [PageNames.ManageContentAssetAuditRails]:
    '/asset/:pageType/:contentId/:assetType/:conCategory/:assetCategory/:metaCategory/audit-trails/:contentIdNumber/:blitzId',
  [PageNames.ManageContentAssetAuditRailsView]:
    '/asset/:pageType/:contentId/:assetType/:conCategory/:assetCategory/:metaCategory/audit-trails/:contentIdNumber/view/:auditId',
  [PageNames.ManageContentAssetAssociatedAssets]:
    '/asset/:pageType/:contentId/:assetType/:conCategory/:assetCategory/:metaCategory/associated-assets/:assetId',

  [PageNames.Role]: '/manage-role',
  [PageNames.RoleCreate]: '/manage-role/create',
  [PageNames.RoleEdit]: '/manage-role/edit/:roleId',
  [PageNames.RoleView]: '/manage-role/view/:roleId',

  [PageNames.User]: '/manage-user',
  [PageNames.UserCreate]: '/manage-user/create',
  [PageNames.UserEdit]: '/manage-user/edit/:userId',
  [PageNames.UserView]: '/manage-user/view/:userId',

  [PageNames.ManageWidget]: `/${ManageWidget}`,

  [PageNames.ManageContainer]: `/${ManageContainer}`,
  [PageNames.SiteNavigation]: `/${SiteNavigation}`,

  // [PageNames.MetaMaster]: '/metamaster',
  // [PageNames.AttributeRepo]: '/metamaster/attribute-repo',
  // [PageNames.AttributeRepoCreate]: '/metamaster/attribute-repo/create',
  // [PageNames.AttributeRepoEdit]: '/metamaster/attribute-repo/edit/:attributeRepoId',
  // [PageNames.AttributeRepoView]: '/metamaster/attribute-repo/view/:attributeRepoId',
  // [PageNames.AssetsDefination]: '/metamaster/assets-defination',
  // [PageNames.AssetsDefinationCreate]: '/metamaster/assets-defination/create',
  // [PageNames.AssetsDefinationEdit]: '/metamaster/assets-defination/edit/:assetsDefinationId',
  // [PageNames.AssetsDefinationView]: '/metamaster/assets-defination/view/:assetsDefinationId',
  // [PageNames.GeoPolicy]: '/metamaster/geo-policy',
  // [PageNames.GeoPolicyCreate]: '/metamaster/geo-policy/create',
  // [PageNames.GeoPolicyEdit]: '/metamaster/geo-policy/edit/:geoPolicyId',
  // [PageNames.GeoPolicyView]: '/metamaster/geo-policy/view/:geoPolicyId',
  // [PageNames.Masters]: '/metamaster/masters',
  // [PageNames.ManageTips]: '/metamaster/manage-tips',

  // [PageNames.MastersCreate]: '/metamaster/masters/:masterName/create',
  // [PageNames.MastersEdit]: '/metamaster/masters/:masterName/edit/:masterId',
  // [PageNames.MastersView]: '/metamaster/masters/:masterName/view/:masterId',

  [PageNames.AuditLogs]: '/audit-logs/item-logs',
  [PageNames.AuditLogsListing]: '/audit-logs/:auditLogsCategory',
  [PageNames.AuditLogsMaster]: '/audit-logs/master-logs',
  [PageNames.AuditLogsContent]: '/audit-logs/content-logs',
  [PageNames.AuditLogsTranscoding]: '/audit-logs/transcoding-logs',
  [PageNames.AuditLogsUserAndRole]: '/audit-logs/user-and-role-logs',

  [PageNames.Reports]: '/reports',
  [PageNames.ReportsDataMigration]: '/reports/data-migration-reports',

  [PageNames.ErrorPage]: '/error',
  [PageNames.NotFound]: '/not-found',
  [PageNames.AccessDenied]: '/access-denied',
  [PageNames.InternalServerError]: '/internal-server-error',

  [PageNames.Segment]: '/manage-segment',
  [PageNames.SegmentCreate]: '/manage-segment/create',
  [PageNames.SegmentEdit]: '/manage-segment/edit/:segmentId',
  [PageNames.SegmentView]: '/manage-segment/view/:segmentId',

  [PageNames.BoltMaster]: '/bolt-master',

  [PageNames.Masters]: '/bolt-master/masters',
  [PageNames.MastersCreate]: '/bolt-master/masters/:masterName/create',
  [PageNames.MastersEdit]: '/bolt-master/masters/:masterName/edit/:masterId',
  [PageNames.MastersView]: '/bolt-master/masters/:masterName/view/:masterId',
  [PageNames.ParameterRepository]: '/bolt-master/parameter-repository',
  [PageNames.ParameterRepositoryCreate]: '/bolt-master/parameter-repository/create',
  [PageNames.ParameterRepositoryEdit]: '/bolt-master/parameter-repository/edit/:masterId',
  [PageNames.ParameterRepositoryView]: '/bolt-master/parameter-repository/view/:masterId',

  [PageNames.ManageTips]: '/bolt-master/manage-tips',

  [PageNames.ContainerDefinitionList]: 'bolt-master/container-definition',
  [PageNames.WidgetDefinitionList]: 'bolt-master/widget-definition',
  [PageNames.ContentStatus]: '/audit-logs/item-status',
}

export const MANAGE_CONTENT_URLS = {
  SHORTS: PageNames.Shorts,
  MOVIES: PageNames.Movies,
  SPORTS: PageNames.Sports,
  TV_SHOW: PageNames.TvShows,
  ORIGINALS: PageNames.Originals,
  NON_VOD_ASSETS: PageNames.NonVODAssest,
  INDEPENDENT_VOD_ASSETS: PageNames.IndependentVODAssest,
}

// Define a function to get page URLs
export const getPageURL = (
  pageName: PageNames,
  options: {
    query?: QueryParameters
    params?: RouteParameters
  },
): string => {
  const replaceParams = (template: string, params: any = {}) => {
    let result = template
    Object.entries(params).forEach(([key, value]: any) => {
      result = result.replace(`:${key}`, value)
    })
    return result
  }

  const { query, params } = options
  const queryParams = query ? `?${new URLSearchParams(query).toString()}` : ''

  return `${replaceParams(PAGE_URL[pageName], params)}${queryParams}`
}
