import {
  BoltMasterIcon,
  HomeIcon,
  RoleManagementIcon,
  SegmentManagementIcon,
  UserManagementIcon,
  ManageWidgetIcon,
  ManageContainerIcon,
  SiteNavigationIcon,
  AuditLogsIcon,
} from '../../../Components/Shared/MUI-Drawer/MUI-Drawer.svgIcons'
import { PAGE_NAME } from '../../PagesRoutes'
import { PAGE_URL, PageNames } from '../../PagesRoutes/PageURLs'
import { BOLT_MODULE } from '../../Roles'

export const DrawerData = [
  {
    lable: PAGE_NAME.HomeLabel,
    icon: <HomeIcon />,
    activeIcon: <HomeIcon color='#402080' />,
    id: 1,
    navigate: true,
    disabled: false,
    path: PAGE_URL[PageNames.Home],
    child: [],
  },
  {
    lable: PAGE_NAME.WidgetManagenmentLabel,
    icon: <ManageWidgetIcon />,
    activeIcon: <ManageWidgetIcon color='#402080' />,
    id: 4,
    navigate: true,
    disabled: false,
    path: PAGE_URL[PageNames.ManageWidget],
    child: [],
    role: BOLT_MODULE.MANAGE_WIDGETS.key,
  },
  {
    lable: PAGE_NAME.SiteNavigationLabel,
    icon: <SiteNavigationIcon />,
    activeIcon: <SiteNavigationIcon color='#402080' />,
    id: 4,
    navigate: true,
    disabled: false,
    path: PAGE_URL[PageNames.SiteNavigation],
    child: [],
    role: BOLT_MODULE.SITE_NAVIGATION.key,
  },
  {
    lable: PAGE_NAME.ContainerManagementLabel,
    icon: <ManageContainerIcon />,
    activeIcon: <ManageContainerIcon color='#402080' />,
    id: 4,
    navigate: true,
    disabled: false,
    path: PAGE_URL[PageNames.ManageContainer],
    child: [],
    role: BOLT_MODULE.MANAGE_CONTAINERS.key,
  },
  {
    lable: PAGE_NAME.SegmentManagementLabel,
    icon: <SegmentManagementIcon />,
    activeIcon: <SegmentManagementIcon color='#402080' />,
    id: 4,
    navigate: true,
    disabled: false,
    path: PAGE_URL[PageNames.Segment],
    child: [],
    role: BOLT_MODULE.MANAGE_SEGMENTS.key,
  },
  {
    lable: PAGE_NAME.RoleManagementLabel,
    icon: <RoleManagementIcon />,
    activeIcon: <RoleManagementIcon color='#402080' />,
    id: 3,
    navigate: true,
    disabled: false,
    path: PAGE_URL[PageNames.Role],
    child: [],
    role: BOLT_MODULE.ROLE_AND_USER_MANAGEMENT.key,
  },
  {
    lable: PAGE_NAME.UserManagementLabel,
    icon: <UserManagementIcon />,
    activeIcon: <UserManagementIcon color='#402080' />,
    id: 4,
    navigate: true,
    disabled: false,
    path: PAGE_URL[PageNames.User],
    child: [],
    role: BOLT_MODULE.ROLE_AND_USER_MANAGEMENT.key,
  },
  {
    lable: PAGE_NAME.AuditLogsLabel,
    icon: <AuditLogsIcon />,
    activeIcon: <AuditLogsIcon color='#402080' />,
    id: 5,
    navigate: false,
    disabled: false,
    path: PAGE_URL[PageNames.AuditLogs],
    role: BOLT_MODULE.AUDIT_TRAILS.key,
    child: [
      {
        lable: PAGE_NAME.ItemLogsLabel,
        icon: '',
        activeIcon: '',
        id: 5.1,
        navigate: true,
        disabled: false,
        path: PAGE_URL[PageNames.AuditLogs],
        role: BOLT_MODULE.AUDIT_TRAILS.key,
        child: [],
      },
      {
        lable: PAGE_NAME.ContentStatus,
        icon: '',
        activeIcon: '',
        id: 5.2,
        navigate: true,
        disabled: false,
        path: PAGE_URL[PageNames.ContentStatus],
        child: [],
        role: BOLT_MODULE.CONTENT_STATUS.key,
      },
    ],
  },
  {
    lable: PAGE_NAME.BoltMasterLabel,
    icon: <BoltMasterIcon />,
    activeIcon: <BoltMasterIcon color='#402080' />,
    id: 6,
    navigate: false,
    disabled: false,
    path: PAGE_URL[PageNames.BoltMaster],
    role: BOLT_MODULE.BOLT_MASTER.key,
    child: [
      {
        lable: PAGE_NAME.ParameterLabel,
        icon: '',
        activeIcon: '',
        id: 6.1,
        navigate: true,
        disabled: false,
        path: PAGE_URL[PageNames.ParameterRepository],
        role: BOLT_MODULE.BOLT_MASTER.key,
        child: [],
      },
      {
        lable: PAGE_NAME.ContainerDefinitionLabel,
        icon: '',
        activeIcon: '',
        id: 6.2,
        navigate: true,
        disabled: false,
        path: PAGE_URL[PageNames.ContainerDefinitionList],
        child: [],
        role: BOLT_MODULE.BOLT_MASTER.key,
      },
      {
        lable: PAGE_NAME.WidgetDefinitionLabel,
        icon: '',
        activeIcon: '',
        id: 6.3,
        navigate: true,
        disabled: false,
        path: PAGE_URL[PageNames.WidgetDefinitionList],
        child: [],
        role: BOLT_MODULE.BOLT_MASTER.key,
      },
      {
        lable: PAGE_NAME.MastersLabel,
        icon: '',
        activeIcon: '',
        id: 6.4,
        navigate: true,
        disabled: false,
        path: PAGE_URL[PageNames.Masters],
        role: BOLT_MODULE.BOLT_MASTER.key,
        child: [],
      },
      {
        lable: PAGE_NAME.ManageTips,
        icon: '',
        activeIcon: '',
        id: 6.5,
        navigate: true,
        disabled: false,
        path: PAGE_URL[PageNames.ManageTips],
        role: BOLT_MODULE.BOLT_MASTER.key,
        child: [],
      },
    ],
  },
  // {
  //   lable: PAGE_NAME.AuditLogsLabel,
  //   icon: <AuditLogsIcon />,
  //   activeIcon: <AuditLogsIcon color='#402080' />,
  //   id: 6,
  //   navigate: true,
  //   disabled: false,
  //   path: PAGE_URL[PageNames.AuditLogs],
  //   role: BOLT_MODULE.AUDIT_TRAILS.key,
  //   child: [],
  //   child: [
  //     {
  //       lable: PAGE_NAME.ContentLogsLabel,
  //       icon: '',
  //       activeIcon: '',
  //       id: 5.4,
  //       navigate: true,
  //       disabled: false,
  //       path: PAGE_URL[PageNames.AuditLogsContent],
  //       role: BOLT_MODULE.BOLT_MASTER.key,
  //       child: [],
  //     },
  //     {
  //       lable: PAGE_NAME.MastersLogsLabel,
  //       icon: '',
  //       activeIcon: '',
  //       id: 5.4,
  //       navigate: true,
  //       disabled: false,
  //       path: PAGE_URL[PageNames.AuditLogsMaster],
  //       role: BOLT_MODULE.BOLT_MASTER.key,
  //       child: [],
  //     },
  //     {
  //       lable: PAGE_NAME.UserAndRoleLogsLabel,
  //       icon: '',
  //       activeIcon: '',
  //       id: 5.4,
  //       navigate: true,
  //       disabled: false,
  //       path: PAGE_URL[PageNames.AuditLogsUserAndRole],
  //       role: BOLT_MODULE.BOLT_MASTER.key,
  //       child: [],
  //     },
  //     {
  //       lable: PAGE_NAME.TranscodingLogsLabel,
  //       icon: '',
  //       activeIcon: '',
  //       id: 5.4,
  //       navigate: true,
  //       disabled: false,
  //       path: PAGE_URL[PageNames.AuditLogsTranscoding],
  //       role: BOLT_MODULE.BOLT_MASTER.key,
  //       child: [],
  //     },
  //   ],
  // },
  // {
  //   lable: PAGE_NAME.ReportsLabel,
  //   icon: <ReportIcon />,
  //   activeIcon: <ReportIcon color='#402080' />,
  //   id: 7,
  //   navigate: true,
  //   disabled: false,
  //   path: PAGE_URL[PageNames.Reports],
  //   child: [],
  //   role: BOLT_MODULE.REPORTING.key,
  // },
]
