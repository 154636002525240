import React, { forwardRef } from 'react'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined'
import InfoIcon from '@mui/icons-material/Info'
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined'
import { Alert, AlertProps, Grid, Snackbar } from '@mui/material'
import Box from '@mui/material/Box'
import { useDispatch } from 'react-redux'
import { showAlert } from '../../../Store/Slice/alert.slice'
import css from './index.module.scss'

const SnackbarAlert = forwardRef<HTMLDivElement, AlertProps>(function SnackbarAlert(props, ref) {
  return <Alert className={css.muiAlert} icon={false} elevation={6} ref={ref} {...props} />
})
interface MainState {
  state: [boolean, string, 'success' | 'info' | 'warning' | 'error']
}
const MUIAlert: React.FC<MainState> = ({ state }) => {
  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(showAlert([false, '', state[2]]))
  }
  const getIcon = () => {
    if (state[2] === 'error') return <ErrorOutlinedIcon />
    if (state[2] === 'warning') return <WarningOutlinedIcon />
    if (state[2] === 'info') return <InfoIcon />
    if (state[2] === 'success') return <CheckCircleRoundedIcon />
    return null
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Snackbar
        autoHideDuration={4000}
        open={state[0]}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        style={{ display: 'flex' }}
      >
        <SnackbarAlert
          icon={false}
          onClose={handleClose}
          severity={state[2]}
          sx={{
            minWidth: '350px',
            minHeight: '45px',
            fontSize: '16px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Grid
            container
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Grid item sx={{ minWidth: '30px', display: 'flex' }}>
              {getIcon()}
            </Grid>
            <Grid item>{String(state[1])}</Grid>
          </Grid>
        </SnackbarAlert>
      </Snackbar>
    </Box>
  )
}

export default MUIAlert
