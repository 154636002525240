import React, { Suspense, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { DialogProvider } from '../Components/Shared'
import Loader from '../Components/Shared/Loader'
import MUIDrawer from '../Components/Shared/MUI-Drawer'
import { DrawerData as initialDrawerData } from '../Constants/Constant/SidebarConstent'

const DefaultLayout: React.FC = () => {
  const [drawerData] = useState(initialDrawerData)

  return (
    <MUIDrawer drawerData={drawerData}>
      <Suspense fallback={<Loader />}>
        <DialogProvider>
          <div className='root'>
            <Loader />
            <Outlet />
          </div>
        </DialogProvider>
      </Suspense>
    </MUIDrawer>
  )
}

export default DefaultLayout
