const CrossIcon = () => {
  return (
    <svg width='26' height='26' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.44658 10.3869C6.31991 10.3869 6.19324 10.3402 6.09324 10.2402C5.89991 10.0469 5.89991 9.7269 6.09324 9.53357L9.86658 5.76023C10.0599 5.5669 10.3799 5.5669 10.5732 5.76023C10.7666 5.95357 10.7666 6.27357 10.5732 6.4669L6.79991 10.2402C6.70658 10.3402 6.57324 10.3869 6.44658 10.3869Z'
        fill='white'
      />
      <path
        d='M10.2199 10.3869C10.0932 10.3869 9.96658 10.3402 9.86658 10.2402L6.09324 6.4669C5.89991 6.27357 5.89991 5.95357 6.09324 5.76023C6.28658 5.5669 6.60658 5.5669 6.79991 5.76023L10.5732 9.53357C10.7666 9.7269 10.7666 10.0469 10.5732 10.2402C10.4732 10.3402 10.3466 10.3869 10.2199 10.3869Z'
        fill='white'
      />
    </svg>
  )
}

export default CrossIcon
