const DETAIL_HEADER_INFO = [
  {
    key: 'containerId',
    title: 'Container ID',
  },
  {
    key: 'segment',
    title: 'Segment',
  },
  {
    key: 'assetType',
    title: 'Asset Type',
  },
  {
    key: 'createdOn',
    title: 'Created On',
    type: 'date',
  },
  {
    key: 'lastUpdatedOn',
    title: 'Last Updated On',
    type: 'date',
  },
  {
    key: 'lastFeaturedOn',
    title: 'Last Featured On',
    type: 'date',
  },
]
const LISTING_HEADER_INFO = [
  {
    key: 'containerId',
    title: 'Container ID',
  },
  {
    key: 'createdOn',
    title: 'Created On',
    type: 'date',
  },
  {
    key: 'lastUpdatedOn',
    title: 'Last Updated On',
    type: 'date',
  },
]
const CONTAINER_HEADER_INFO = [
  {
    key: 'containerId',
    title: 'Container ID',
  },
  {
    key: 'segment',
    title: 'Segment',
  },
  {
    key: 'lastFeaturedOn',
    title: 'Last Featured On',
    type: 'date',
  },
  {
    key: 'createdOn',
    title: 'Created On',
    type: 'date',
  },
  {
    key: 'lastUpdatedOn',
    title: 'Last Updated On',
    type: 'date',
  },
]

export const CONSTANTS = {
  PREVIE_IMAGE_GENERATION_DEBOUNCE_TIME: 3000,
  INCORRECT_URL_FORMAT_MESSAGE: 'Incorrect URL format',
  S3_URL_REQUIRED_MESSAGE: 'S3 url is required',
  ERROR_TEXT: 'Error',
  CUE_POINT_LIST: 'cuePointList',
  WARNING_TEXT: 'Warning',
  URL_VALIDATION_REGEX: new RegExp(process.env.REACT_APP_CLOUDFRONT_BASE_URL || '', 'g') as any,
  IMAGE_CONTENT_TYPE_ERROR_MESSAGES: {
    ASPECT_RATIO_ERROR_MESSAGE: 'Incorrect Aspect Ratio',
    IMAGE_RESOLUTION_WARNING_MESSAGE: 'Image resolution doesn`t match',
    IMAGE_RESOLUTION_ERROR_MESSAGE: 'Resolution limit exceeded',
    IMAGE_RATIO_AND_RESOLUTION_ERROR_MESSAGE: 'Aspect Ratio and Image resolution, limit exceeded',
  },
  MEDIA_IMAGE: {
    CONTENT_MEDIA_IMAGE_VARIANT_TEXT: 'CONTENT_MEDIA_IMAGE_VARIANT',
    ALL_COUNTRIES_TEXT: 'All Countries',
    ALL_COUNTRIES_VALUE: ['All'],
    URL_IMAGE_TYPE: 'URL',
    UPLOAD_IMAGE_TYPE: 'UPLOAD',
    VALID_IMAGE_FILENAME_REGEX: /^([a-zA-Z0-9\s_\\.\-:])+(.jpg|.jpeg|.png|.gif|.bmp)$/,
  },
  DATE_TIME_FORMAT_VIEWS: ['year', 'month', 'day', 'hours', 'minutes'],
  TIME_FORMAT_VIEWS: ['hours', 'minutes'],
  YEAR_FORMAT: ['year'],
  MODIFIED_DATA_HEADING: 'Modified Data',
  PRIMARY_IMAGE_TITLE_TEXT: 'Image',
  VARIANT_IMAGE_TITLE_TEXT: 'Variant_',
  S3_BUCKET_BASE_URL: process.env.REACT_APP_S3_BUCKET_BASE_URL as string,
  S3_BUCKET_IMAGE_BASE_FOLDER: process.env.REACT_APP_S3_BUCKET_BASE_FOLDER as string,
  S3_SUBTITLE_BUCKET_BASE_URL: process.env.REACT_APP_S3_SUBTITLE_BUCKET_BASE_URL as string,
  CLOUDFRONT_BASE_URL: process.env.REACT_APP_CLOUDFRONT_BASE_URL as string,

  CLOUDFRONT_BASE_URL_SUBTITLE: process.env.REACT_APP_CLOUDFRONT_BASE_URL_SUBTITLE as string,

  STANDALONE_PUBLISHING_TEXT: 'Standalone Publishing',
  ASSET_TYPE_TITLE_TEXT: 'Asset Type Title',
  BASE_URL: process.env.REACT_APP_API_BASE_URL as string,
  SESSION_END_URL: process.env.REACT_APP_CYBERARK_CLIENT_SESSION_END_URL as string,
  LOCAL_KEYS: {
    AUTH_CONFIG: 'auth_config',
    USER_DATA: 'user_data',
    ROLE_DATA: 'role_data',
    LOGOUT_MESSAGE: 'logout_message',
  },
  QUERY_CONFIG: {
    refetchOnMountOrArgChange: true,
    skip: false,
  },
  API_INTERVAL_TEN: 10000,
  ROLE_STATUS: [
    { value: 'ACTIVE', label: 'Active', type: 'status-active' },
    { value: 'INACTIVE', label: 'In Active', type: 'status-inactive' },
  ],
  FILTER_LIST: [
    { label: 'Is equal to', value: 'equals' },
    { label: 'Is not equal to', value: 'notEquals' },
    { label: 'Starts With', value: 'startsWith' },
    { label: 'Ends With', value: 'endsWith' },
    { label: 'Contains', value: 'contains' },
  ],
  FILTER_LIST_ADVANCED_SEARCH: [
    { label: 'Is', value: 'equals' },
    { label: 'Is Not', value: 'notEquals' },
    { label: 'Greater Than', value: 'greaterThan' },
    { label: 'Less Than', value: 'lessThan' },
  ],
  DAY_WEEK_FILTER: [
    { label: 'Next(Day)', value: 'nextDay' },
    { label: 'Next(Week)', value: 'nextWeek' },
  ],

  STRING_FILTER: [
    { label: 'Is equal to', value: 'equals' },
    { label: 'Is not equal to', value: 'notEquals' },
    { label: 'Starts With', value: 'startsWith' },
    { label: 'Ends With', value: 'endsWith' },
    { label: 'Contains', value: 'contains' },
    { label: 'In', value: 'in' },
  ],
  BULK_IMAGE_TYPE: [{ label: 'Primary Image PARAMETER', value: 'primary_image_atttribute' }],
  NUMBER_DATE_FILTER: [
    { label: 'Is equal to', value: 'equals' },
    { label: 'Is not equal to', value: 'notEquals' },
    { label: 'Greater Than', value: 'greaterThan' },
    { label: 'Greater Than Equal', value: 'greaterThanEquals' },
    { label: 'Less Than', value: 'lessThan' },
    { label: 'Less Than Equals', value: 'lessThanEquals' },
  ],
  VIDEO_SECTION_TIPS: [
    'Be Cautious, when you select "Skip Validation," you\'re granting users the ability to override system validations.',
    'To add an existing group, remove newly created group first. Then, you can choose an existing group.',
  ],
  CREATE_ASSET_DEFINITION_TIPS: [
    'Be Cautious, when you select "Skip Validation," you\'re granting users the ability to override system validations.',
  ],
  CREATE_ROLE_TIPS: [
    'Be Cautious, when you select "Skip Validation," you\'re granting users the ability to override system validations.',
    'Role names should be distinct and not match any existing role names.',
    'By default, roles are set to "Active." You can modify the status in the "Edit Role" section.',
    'You can make changes to role settings and configurations later.',
  ],
  CREATE_GEO_POLICY_TIPS: [
    'Be Cautious, when you select Skip Validation, you are granting users the ability to override system validations.',
    'Role names should be distinct and not match any existing role names.',
    'By default, roles are set to "Active." You can modify the status in the "Edit Role" section.',
    'You can make changes to role settings and configurations later.',
  ],
  NO_TIPS: ['No tips added'],
  PLACEHOLDER_TIPS: [
    'Refer Parameter definition section to understand the mapping of the Parameter with different asset types. ',
  ],
  CREATE_USER_TIPS: ['Each user can be assigned only one role at a time.'],
  MANAGE_SEGMENTS: [
    'Be Cautious, when you select "Skip Validation," you are granting users the ability to override system validations.',
  ],
  STATUS_ACTIVE_TITLE: 'Activate Role',
  STATUS_INACTIVE_TITLE: 'Deactivate Role',
  PARAMETER_STATUS_INACTIVE_TITLE: 'Deactivate Parameter',
  UNSAVE_CHANGES: 'Unsaved Changes',
  UNSAVED_CHANGES_DESCRIPTION:
    'Your changes have not been saved. Make sure to save your data to prevent any loss of entered information.',
  UNSAVED_CHANGES_DESCRIPTION_BULK_IMAGES:
    'Your changes have not been saved. Make sure to save your data to prevent any loss of selected images.',
  STATUS_ACTIVE_CONTENT:
    'Are you sure you want to activate the previously deactivated role  “Content Creator”? This will restore the roles permissions and access.',
  STATUS_INACTIVE_CONTENT:
    'Are you sure you want to deactivate the role “Content Creator”? This will  suspend the roles permissions and access. You can reactivate it later.',

  USER_STATUS_ACTIVE_TITLE: 'Activate User',
  USER_STATUS_INACTIVE_TITLE: 'Deactivate User',

  USER_STATUS_ACTIVE_CONTENT: 'Are you sure you want to activate this User?',
  USER_STATUS_INACTIVE_CONTENT:
    'Are you sure you want to activate this User? All permission associated will be deleted.',

  CONTAINER_DEFINITION_ACTIVE_TITLE: 'Activate Container Type',
  CONTAINER_DEFINITION_INACTIVE_TITLE: 'Deactivate Container Type',
  WIDGET_DEFINITION_ACTIVE_TITLE: 'Activate Widget Type',
  WIDGET_DEFINITION_INACTIVE_TITLE: 'Deactivate Widget Type',

  DELETE_ASSET: 'Delete Asset',
  CHANGES_MADE: 'Changes Made',
  CHANGES_MADE_DESCRIPTION:
    'Changes have been made to this meta-category by some other user, please reload the page to view the changes',
  UNSAVED_CHANGES: 'Unsaved Changes',
  UNSAVED_CHANGES_MESSAGE:
    'Your changes have not been saved. Make sure to save your data to prevent any loss of entered information.',
  DELETE_ASSET_DESCRIPTION: 'Are you sure you want to delete this asset?',
  ASSET_DEFINITION_ACTIVE_CONTENT: 'Are you sure you want to activate this asset type?',
  ASSET_DEFINITION_INACTIVE_CONTENT: 'Are you sure you want to deactivate this asset type?',

  DELETE_ROLE_TITLE: 'Delete Role',

  DELETE_SEGMENT_TITLE: 'Delete Segment',

  DELETE_ROLE_CONTENT:
    'Are you sure you want to delete the role “Content Creator”? This action cannot be undone and will also affect the permissions of associated users.',
  CLONE_ASSET: 'Clone Asset',
  CLONE_ASSET_DESC: 'Enter the number of clones you want to create for this asset',
  CLONED_ASSET: 'Cloned Asset',
  CLONE_ASSET_SUCCESS:
    'The asset has been successfully cloned. Below are the links to the cloned assets',
  CLONE_ASSET_SUCCESS2: 'List of Cloned Assets',
  DEPUBLISH_CHILD_ASSET_SUCCESS: 'List of Child Assets',
  ROLE_EDITOR_MODULE_KEYS: {
    BOLT_MODULE: 'BOLT_MODULE',
    META_CATEGORIES: 'META_CATEGORIES',
    TOLLGATES: 'TOLLGATES',
  },
  HTTP_METHOD: {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    PATCH: 'patch',
    DELETE: 'delete',
  },
  DEFAULT_LANGUAGE_WARNING:
    // eslint-disable-next-line quotes
    "To enable 'Fetch & validate', choose the 'Default language' in Descriptive metadata and the language for the selected file. Ensure the selected language for the files, matches the default language.",
  USER_ROLE_DEACTIVATE_MSG: `This role is inactive. Please go to Role Management section to activate this
                    role. You can still assign users to an inactive role.`,
  ROLE_LISTING_COLUMNS_NAME: [
    { id: 'roleName', label: 'Role Name', sort: true },
    { id: 'associatedUsers', label: 'Associated Users', sort: true },
    { id: 'createdAt', label: 'Role Creation Date', sort: true },
    { id: 'updatedAt', label: 'Last Updated Date', sort: true },
    { id: 'status', label: 'Status', sort: true },
  ],
  USER_LISTING_COLUMNS: [
    { id: 'userName', label: 'User Name', sort: true },
    { id: 'cyberarkId', label: 'CyberArk ID', sort: true },
    { id: 'userEmail', label: 'Email ID', sort: true },
    { id: 'roleName', label: 'Role Name ', sort: true },
    { id: 'createdAt', label: 'User Creation Date', sort: true },
    { id: 'status', label: 'User Status', sort: true },
  ],
  WIDGET_LISTING_COLUMNS: [
    { id: 'widgetTitle', label: 'Title', sort: true, dataType: 'String', showFilter: true },
    { id: 'widgetId', label: 'ID', sort: true, dataType: 'Number', showFilter: true },
    { id: 'type', label: 'Type', sort: true, dataType: 'String', showFilter: false },
    { id: 'layout', label: 'Layout', sort: true, dataType: 'String', showFilter: false },
    { id: 'engine', label: 'Data Engine', sort: true, dataType: 'String', showFilter: true },
    { id: 'state', label: 'State', sort: true, dataType: 'String', showFilter: false },
    { id: 'segment', label: 'Segment', sort: false, dataType: 'String', showFilter: true },
    { id: 'createdAt', label: 'Creation Date', sort: true, dataType: 'Date', showFilter: true },
    { id: 'updatedAt', label: 'Last Update Date', sort: true, dataType: 'Date', showFilter: true },
    {
      id: 'lastFeaturedDate',
      label: 'Last Featured Date',
      sort: true,
      dataType: 'Date',
      showFilter: true,
    },
  ],
  CONTAINER_LISTING_COLUMNS: [
    { id: 'containerTitle', label: 'Title', sort: true, dataType: 'String', showFilter: true },
    { id: 'containerId', label: 'ID', sort: true, dataType: 'Number', showFilter: true },
    { id: 'type', label: 'Type', sort: true, dataType: 'String', showFilter: true },
    { id: 'layout', label: 'Layout', sort: true, dataType: 'String', showFilter: false },
    { id: 'state', label: 'State', sort: true, dataType: 'String', showFilter: false },
    { id: 'segment', label: 'Segment', sort: false, dataType: 'String', showFilter: true },
    { id: 'language', label: 'Language', sort: false, dataType: 'String', showFilter: true },
    { id: 'createdAt', label: 'Creation Date', sort: true, dataType: 'Date', showFilter: true },
    { id: 'updatedAt', label: 'Last Update Date', sort: true, dataType: 'Date', showFilter: true },
    {
      id: 'lastFeaturedDate',
      label: 'Last Featured Date',
      sort: true,
      dataType: 'Date',
      showFilter: true,
    },
  ],
  CHILD_CONTAINER_LISTING_COLUMNS: [
    { id: 'containerTitle', label: 'Title', sort: true },
    { id: 'containerId', label: 'ID', sort: true },
    { id: 'type', label: 'Type', sort: true },
    { id: 'layout', label: 'Layout', sort: true },
    { id: 'state', label: 'State', sort: true },
    { id: 'segment', label: 'Segment', sort: false },
    { id: 'language', label: 'Language', sort: false },
    { id: 'createdAt', label: 'Creation Date', sort: true },
    { id: 'updatedAt', label: 'Last Update Date', sort: true },
    { id: 'lastFeaturedDate', label: 'Last Featured Date', sort: true },
  ],
  CONTAINER_MODAL_COLUMNS: [
    { id: 'checkbox', label: ' ', sort: false },
    { id: 'containerId', label: 'ID', sort: true },
    { id: 'title', label: 'Title', sort: true },
    { id: 'type', label: 'Type', sort: true },
    { id: 'layout', label: 'Layout', sort: true },
  ],
  SITE_NAVIGATION_LISTING_COLUMNS: [
    { id: 'siteNavigationTitle', label: 'Title', sort: true, dataType: 'String', showFilter: true },
    { id: 'siteNavigationId', label: 'ID', sort: true, dataType: 'Number', showFilter: true },
    { id: 'layout', label: 'Layout', sort: true, dataType: 'String', showFilter: false },
    { id: 'state', label: 'State', sort: true, dataType: 'String', showFilter: false },
    { id: 'segment', label: 'Segment', sort: false, dataType: 'String', showFilter: true },
    { id: 'createdAt', label: 'Creation Date', sort: true, dataType: 'Date', showFilter: true },
    { id: 'updatedAt', label: 'Last Update Date', sort: true, dataType: 'Date', showFilter: true },
    {
      id: 'lastFeaturedDate',
      label: 'Last Featured Date',
      sort: true,
      dataType: 'Date',
      showFilter: true,
    },
  ],
  SITE_NAVIGATION_MODAL_LISTING_COLUMNS: [
    { id: 'checkbox', label: ' ', sort: false },
    { id: 'siteNavigationId', label: 'ID', sort: true },
    { id: 'title', label: 'Title', sort: true },
    { id: 'type', label: 'Type', sort: true },
    { id: 'layout', label: 'Layout', sort: true },
  ],
  ADD_ASSET_TYPE_IN_RESPONSE: ['spotlight layout'],

  GEO_POLICY_LISTING_COLUMN: [
    { id: 'policyName', label: 'Policy Name', sort: true },
    { id: 'timeDifference', label: 'Time Difference', sort: true },
    { id: 'countries', label: 'Countries', sort: true },
    { id: 'days', label: 'Day', sort: true },
    { id: 'description', label: 'Discription', sort: true },
    { id: 'status', label: 'Status', sort: true },
  ],
  ASSOCIATED_ASSETS_LISTING_COLUMN: [
    { id: 'image', label: 'Image', sort: false },
    { id: 'assetTitle', label: 'Asset Title', sort: true },
    { id: 'state', label: 'Asset State', sort: true },
    { id: 'assetCategory', label: 'Asset Category', sort: true },
    { id: 'contentId', label: 'Asset ID', sort: true },
    { id: 'createdAt', label: 'Creation Date', sort: true },
  ],

  GEO_POLICY_LISTING_RESPONSE: [
    {
      _id: '656d7cb7c737d64b63b1b715',
      policyName: 'Geo_1',
      description: 'Geo policy description ',
      timeDifference: 7200,
      countries: ['nepal', 'bangladesh'],
      days: ['fri', 'sat'],
      createdBy: '654dca4be34dab5b60111783',
      updatedBy: '654dca4be34dab5b60111783',

      createdAt: '2023-12-04T07:16:07.612Z',
      updatedAt: '2023-12-04T07:16:07.612Z',
      status: 'Active',
    },
  ],
  ROLE_USER_LISTING_COLUMNS: [
    //  { id: 'roleName', label: 'Role Name ', sort: true },
    { id: 'userName', label: 'User Name', sort: true },
    { id: 'createdAt', label: 'User Creation Date', sort: true },
    { id: 'userEmail', label: 'Email ID', sort: true },
    { id: 'status', label: 'Status', sort: true },
  ],
  MEDIA_INFO_COLUMN: [
    { id: 'action', label: 'Actions', sort: false },
    { id: 'videoUrl', label: 'URL Field', sort: false },
    { id: 'audioLanguages', label: 'Audio Language', sort: false },
    { id: 'platform', label: 'Platform', sort: false },
    { id: 'videoType', label: 'Video Type', sort: false },
    { id: 'status', label: 'Status', sort: false },
    { id: 'fps', label: 'FPS', sort: false },
    { id: 'encodingProfile', label: 'Encoding Profile', sort: false },
    { id: 'audioCodec', label: 'Audio Codec', sort: false },
    { id: 'videoCodec', label: 'Video Codec', sort: false },
    { id: 'drmId', label: 'DRMID', sort: false },
    { id: 'daiAssetKey', label: 'DAI asset key', sort: false },
    { id: 'isSource', label: 'Is Source', sort: false },
    { id: 'additionalDataJson', label: 'Additional Data', sort: false },
  ],
  BOLT_ERROR_CODE_BLOCK_DELETE_USER: 'BOLT_06014',
  BOLT_ORCHESTRATION_EXISTING_ASSET_SAVE_WARNING: 'BOLT_20014',
  BOLT_ERROR_CODE_BLOCK_DELETE_PARAMETER: 'BOLT_04011',
  BOLT_ERROR_CODE_VALIDATION_ASSET_CREATE: 'BOLT_01002',
  BOLT_ERROR_CODE_VALIDATION_ASSET_MARK_AS_DONE: 'BOLT_14021',
  BOLT_ERROR_CODE_VALIDATION_VIDEO: 'BOLT_14009',
  BOLT_ERROR_CODE_PARENTID_PARAMETER_MISSING: 'BOLT_14051',

  PAGE_TYPE: {
    CREATE: 'create',
    VIEW: 'view',
    EDIT: 'edit',
    VIEW_ONLY: 'view-only',
    UPDATE: 'update',
  },
  PARAMETER_STATUS_ACTIVE_TITLE: 'Activate Parameter',
  GEO_POLICY_STATUS_ACTIVE_TITLE: 'Activate Geo Policy',
  ATTEIBUTE_DELETE_BLOCK_MESSAGE: 'Invalid Parameter Or Parameter Does Not Exists in Our System',
  PARAMETER_STATUS_DEACTIVE_TITLE: 'Deactivate Parameter',
  GEO_POLICY_STATUS_DEACTIVE_TITLE: 'Deactivate Geo Policy',
  STATUS_ACTIVE_PARAMETER_DESCRIPTION:
    'Are you sure you want to activate the previously deactivated Parameter',
  STATUS_ACTIVE_GEO_POLICY_DESCRIPTION:
    'Are you sure you want to activate the previously deactivated Geo Policy',
  // STATUS_DEACTIVE_PARAMETER_DESCRIPTION: (name: string) =>
  //   `Are you sure you want to deactivate this Parameter ${name} `,
  STATUS_DEACTIVE_GEO_POLICY_DESCRIPTION: 'Are you sure you want to deactivate the Geo Policy',
  DELETE_PARAMETER_DESCRIPTION1: 'Are you sure you want to delete ',
  DELETE_PARAMETER_DESCRIPTION2: '? As this Parameter may be mapped to existing assets',
  DELETE_GEO_POLICY_DESCRIPTION1: 'Are you sure you want to delete ',
  DELETE_GEO_POLICY_DESCRIPTION2: '? As this Geo Policy may be mapped to existing assets',
  DISABLED_PERMISSION_ROLE_MODULES: ['FEATURING', 'UNFEATURE'],
  DELETE_GEO_POLICY_TITLE: 'Delete Geo Policy',

  MESSAGES: {
    IMAGE_UNSAVED_CHANGES: 'Unsaved Changes',
    IMAGE_UNSAVED_CHANGES_DESCRIPTION:
      'Selecting a different Image type option will result in the loss of currently added images.',
    REMOVE_IMAGE_HEADING: 'Remove Image?',
    REMOVE_IMAGE_DESCRIPTION: 'Are you sure you wish to remove this image?',
    // eslint-disable-next-line quotes
    PUBLISH_DISABLE_MESSAGE: "You don't have permission to publish",
    STANDALONE_DISABLE_MESSAGE: 'To publish this asset, please publish its parent first.',
    FILE_DOWNLOADED: 'File Downloaded',
    ARCHIEVED_VERSION_EMPTY: 'No previously published version available.',
    NO_DATA_FOUND: 'No Data Found',
    COUNT_INVALID: 'Please select number of copies to clone.',
    NO_METACATEGORY_MSG: 'No Meta-Category Defined',
    CHANGE_STATUS_MESSAGE: (msg: string) => `Role ${msg} Successfully`,
    EMAIL_ALREADY_EXIST: 'Entered Email ID already exists!',
    EMAIL_INVALID: 'Entered Email ID is invalid!',
    CYBERARKID_ALREADY_EXIST: 'Entered Cyberark ID already exists!',
    CYBERARKID_INVALID: 'Entered Cyberark ID is invalid!',
    ROLE_CREATION_SUCCESS: 'Role created successfully',
    NO_DATA_CONTENT_ID: 'Data with this content id is not available',
    NO_ASSET_TYPE_DATA: 'Data with this asset type is not available',
    DELETE_BLOCK_MESSAGE: `Role deletion is blocked due to associated users; visit 'Associated Users' in Quick Links to find associated users or assign new role in the 'User Management' section before proceeding with deletion. ${''}`,
    INACTIVE_BLOCK_MESSAGE: `Role deactivation is blocked due to associated users; visit 'Associated User' in 'Quick Links' to unmap users or assign new role in the 'User Management' section before proceeding with deactivation.${''}`,
    STATUS_CHANGE_BLOCK_MESSAGE: `Role status change is blocked due to associated users; visit 'Associated User' in 'Quick Links' to unmap users or assign new role in the 'User Management' section before proceeding with status change. ${''}`,
    EDIT_DRAFT_EXIST:
      'Editing this asset version will transit this version to ‘Draft’ and it will override the existing draft version. Please ensure no further action is required in the existing draft version as it will get lost.  Do you wish to proceed?',
    EDIT_DRAFT_NOT_EXIST:
      'If you need to modify this asset, a new ‘Draft’ version will be created, allowing you to make the necessary changes. Do you wish to proceed?',
    SCHEDULE_FUTURE_EXIST:
      'Schedule already set for future time. Creating an earlier schedule will only be available for limited time until the later asset version scheduled for later time gets published.',
    SCHEDULE_EARLIER_EXIST:
      'Schedule already set for earlier time. Creating a schedule for later time will display the previous schedule asset version changes for limited time.',
    SCHEDULE_BOTH_EXIST:
      'Schedule already set for future & later time. Creating a new schedule will be visible for limited time until the other scheduled asset versions gets published.',
    SCHEDULE_SAME_EXIST:
      'Please select a different time for asset scheduling due to an existing schedule conflict.',
    SCHEDULE_DRAFT_EXIST:
      'Removing this schedule version will transit this version to ‘Draft’ and it will override the existing draft version. Please ensure no further action is required in the existing draft version as it will get lost. Do you wish to proceed?',
    SCHEDULE_DRAFT_NOT_EXIST:
      'Removing this schedule version will transit this version to ‘Draft’. Do you wish to proceed?',
    DE_PUBLISH_VERSIONS_EXIST:
      'Multiple versions of this asset are available. Depublishing will only affect the selected version, the other versions will remain unchanged. Do you wish to proceed with depublishing this asset version?',
    DE_PUBLISH_LINKED_EXIST:
      'Depublishing is not allowed for this asset due to associated child assets with standalone publishing set to ‘No.’ Please depublish/unlink/remove the schedule(s) of the child asset(s) before proceeding.',
    DE_PUBLISH_CONFIRM: 'Are you sure you want to de-publish this asset?',
    DE_PUBLISH: 'De-Publish',
    DRAFT_UPDATED: 'Draft version updated',
    DRAFT_UPDATE: 'Draft Update',
    DRAFT_CREATED: 'Draft version created',
    DRAFT_CREATE: 'Draft Create',
    CREATE_SCHEDULE: 'Create Schedule',
    DELETE_SCHEDULE: 'Remove Schedule',
    API_ERROR: 'Something wents wrong. Please try after sometime!',
    CONTENT_PUBLISHED: 'Content published successfully!',
    CONTENT_PUBLISHED_SCHEDULED: 'Content scheduled for immediate publishing!',
    CONTENT_SCHEDULED: 'Content scheduled successfully.',
    CONTENT_SCHEDULE_DELETE: 'Schedule deleted successfully!',
    SCHEDULE_DATE_FORMAT: 'Strings must use singlequote',
    CONTENT_DE_PUBLISHED: 'Asset version de-published successfully!',
    SESSION_END: 'Your session has been expired. Please log in again!',
    ROLE_SESSION_END: 'Your role has been updated. Please log in again',
    LOGOUT: 'You have been successfully logged out!',
    CHANGE_STATUS_MESSAGE_PARAMETER: (msg: string) => `Parameter ${msg} Successfully`,
    INACTIVE_BLOCK_MESSAGE_PARAMETER: `Can not deactivate parameter, assigned to existing widget/container type and widget/container has been created.${''}`,
    GENERIC_ERROR_MESSAGE: 'An unexpected error occurred. Please try again later.',
    PARAMETER_STATUS_INACTIVE_TITLE: 'Deactivate Parameter',
    PARAMETER_CREATION_SUCCESS: 'Parameter created successfully',
    PARAMETER_BLOCK_ERROR:
      'Can not remove parameter, assigned to existing context type and context has been created.',
    SEGMENT_BLOCK_ERROR:
      'Can not remove segment, assigned to existing context type and context has been created.',
  },
  OTHER_VERSIONS: {
    AVAILABLE: 'Other Available Versions',
    LAST_UPDATED: 'Last updated on',
    SCHEDULE_FOR: 'Scheduled For',
    SCHEDULE_FOR_PUBLISHED: 'Scheduled for Published',
    DRFAT: 'Draft',
  },
  DATE_N_TIME_FORMAT: 'dd MMMM yyyy, HH:mm',
  DATE_N_TIME_FORMAT_SECONDS: 'dd MMMM, yyyy, HH:mm:ss',
  TIME_FORMAT: 'dd MMMM, yyyy, HH:mm',
  TIME_FORMAT_1: 'dd MMM, yyyy, HH:mm',
  TIME_FORMAT_2: 'dd MMM, yyyy, HH:mm',
  TIME_FORMAT_3: 'dd/MM/yyyy HH:mm',
  DATE_FORMAT: 'dd/MM/yyyy',
  DATE_FORMAT_TIME: 'dd/MM/yyyy HH:mm',
  DATE_FORMAT_TIME_SECONDS: 'dd/MM/yyyy HH:mm:ss',
  DATE_YEAR_FORMAT: 'yyyy',
  DATE_FORMAT_4: 'yyyy-MM-dd HH:mm:ss',
  DATE_FORMAT_5: 'dd MMMM, yyyy, HH:mm:ss',

  SCHEDULE_TITLE: 'Schedule Title',
  SCHEDULE_TIME: 'Schedule Date and Time',
  BTN_TYPE_OUTLINED: 'outlined',
  BTN_TYPE_CONTAINED: 'contained',
  BTN_LABEL_NO: 'No',
  BTN_LABEL_YES: 'Yes',
  BTN_LABEL_OKEY: 'Okay',
  BTN_LABEL_CANCEL: 'Cancel',
  BTN_LABEL_SUBMIT: 'Submit',
  BTN_LABEL_PROCEED: 'Proceed',
  BTN_LABEL_PUBLISH_LATER: 'Publish Later',
  BTN_LABEL_PUBLISH_NOW: 'Publish Now',
  BTN_LABEL_DE_PUBLISH: 'De-Publish Now',
  BTN_LABEL_EDIT_SCHEDULE: 'Edit Schedule',
  BTN_LABEL_DELETE_SCHEDULE: 'Remove Schedule',
  TYPO_PUBLISHING_SCHEDULE: 'Publishing Schedule',
  META_CATEGORIES_SORTING: {
    KEY: 'metaCategoryKey',
    KEY2: 'categoryKey',
    ORDER: [
      'RIGHTS_AND_AVAILABILITY',
      'BUSINESS_RULES',
      'CONTENT_MEDIA_VIDEO',
      'CONTENT_MEDIA_IMAGE',
      'DESCRIPTIVE_METADATA',
      'TECHNICAL_METADATA',
      'ADVERTISEMENT_METADATA',
      'SEO_METADATA',
      'SPORTS_METADATA',
      'MISCELLANEOUS_METADATA',
    ],
  },

  STANDALONE_PUBLISHING: [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ],
  COMMON_YES_NO: [
    { value: 'no', label: 'No' },
    { value: 'yes', label: 'Yes' },
  ],
  defalutParameterValue: {
    parameterName: 'ExampleParam2',
    fieldName: 'Example2',
    fieldType: 'Number',
    dataType: 'Integer',
    defaultValue: 'any',
    esField: 'NA',
    mandatory: 'no',
    placeholder: 'Enter number',
    addMore: 'no',
    master: 'NA',
    multiSelect: 'yes',
    purpose: 'Description',
    status: 'INAVTIVE',
    _id: '666a727f2f528d94ff8ae890',
  },
  MASTERS_KEY_ALLOWED_PARENTS: 'ALLOWED_PARENTS',
  MASTERS_KEY_CONTENT_CATEGORY: 'CONTENT_CATEGORY',
  MASTERS_KEY_ASSET_CATEGORY: 'ASSET_CATEGORY',
  MASTERS_KEY_SCOPE: 'SCOPE',
  MASTERS_KEY_INHERITANCE_RULE: 'INHERITANCE_RULE',
  MASTERS_KEY_STANDALONE_PUBLISHED: 'STANDALONE_PUBLISHED',
  MASTERS_KEY_DATA_SOURCE: 'DATA_SOURCE',
  activeText: 'active',
  ActiveText: 'Active',
  InActiveText: 'In Active',

  YES_NO_LIST: [
    {
      label: 'Yes',
      value: 'Yes',
    },
    {
      label: 'No',
      value: 'No',
    },
  ],

  STATUS_LIST: [
    { value: 'ACTIVE', label: 'Active', type: 'status-active' },
    { value: 'INACTIVE', label: 'In Active', type: 'status-inactive' },
  ],

  DATA_TYPE_LIST: [
    { key: 'BOOLEAN', value: 'BOOLEAN' },
    { key: 'DATE', value: 'DATE' },
    { key: 'IMAGE_TYPE', value: 'IMAGE_TYPE' },
    { key: 'JSON', value: 'JSON' },
    { key: 'NUMBER', value: 'NUMBER' },
    { key: 'STRING', value: 'STRING' },
    // as discussed with BE this option has been added
  ],

  PAGINATION_OPTIONS: [
    { value: 10, label: 10 },
    { value: 20, label: 20 },
    { value: 50, label: 50 },
  ],

  STATUS_LABEL: {
    ACTIVE: 'Active',
    INACTIVE: 'In Active',
  },
  IMAGE_CONTENT_TYPE_CARD_DETAILS: {
    aspectRatio: '',
    imageResolution: '',
    imageSrc: '',
    imageUrl: '',
    targeting: '',
    segmentId: '',
  },

  AUTOCOMPLETE_EVENT_TYPES: {
    INPUT: 'input',
    CLEAR: 'clear',
    SELECTOPTION: 'selectOption',
    REMOVEOPTION: 'removeOption',
  },

  FIELDTYPES: {
    TEXT: 'TEXT',
    TEXTAREA: 'TEXTAREA',
    CHECKBOX: 'CHECKBOX',
    SWITCH: 'SWITCH',
    DATE: 'DATE',
    DATETIME: 'DATETIME',
    YEAR: 'YEAR',
    TIME: 'TIME',
    COMPLEXTYPE: 'COMPLEXTYPE',
    RADIO: 'RADIO',
    SELECT: 'SELECT',
    NUMBER: 'NUMBER',
    FILE: 'FILE',
    BOOLEAN: 'BOOLEAN',
  },
  CATEGORIES: {
    DESCRIPTIVE_METADATA: 'DESCRIPTIVE_METADATA',
    RIGHTS_AND_AVAILABILITY: 'RIGHTS_AND_AVAILABILITY',
    SEO_METADATA: 'SEO_METADATA',
    ADVERTISEMENT_METADATA: 'ADVERTISEMENT_METADATA',
    BUSINESS_RULES: 'BUSINESS_RULES',
    TECHNICAL_METADATA: 'TECHNICAL_METADATA',
    CONTENT_MEDIA_IMAGE: 'CONTENT_MEDIA_IMAGE',
    CONTENT_MEDIA_VIDEO: 'CONTENT_MEDIA_VIDEO',
    SPORTS_METADATA: 'SPORTS_METADATA',
    MISCELLANEOUS_METADATA: 'MISCELLANEOUS_METADATA',
    CHECKLIST: 'CHECKLIST',
  },

  MARK_DONE_STATUS: {
    FAIL: '0',
    PASS: '1',
    NO_ACTION: '2',
  },

  MARK_DONE_LABEL: 'Mark as Done',

  ASSET_CREATION_OBJ: {
    SELECT: 'SELECT',
    ADDMORE: 'ADDMORE',
    COMPLEX: 'COMPLEX',
    REMOVE: 'REMOVE',
    NORMAL: 'NORMAL',
    BOOLEAN: 'BOOLEAN',
  },
  LISTING_LIMIT_OPTIONS: [
    { label: 10, value: 10 },
    { label: 20, value: 20 },
    { label: 50, value: 50 },
  ],
  STATE_DISPLAY: {
    FEATURED: 'Featured',
    UNFEATURED: 'Unfeatured',
  } as Record<string, string>,
  tempSegmentmasterlist: [
    'country',
    'platform',
    'cluster',
    'publishing-segment',
    'ab-segment',
    'state',
    'segments',
  ],

  LISTING_STATE_OPTIONS: [
    { value: '', label: 'Filter by State' },
    { value: 'CREATED', label: 'Draft-Created' },
    { value: 'DRAFT', label: 'Draft' },
    { value: 'DRAFT_COMPLETE', label: 'Draft-Complete' },
    { value: 'DRAFT_UNDER_QC', label: 'Draft-Under QC' },
    { value: 'READY_FOR_PUBLISHING', label: 'Ready for Publishing' },
    { value: 'PUBLISHED', label: 'Published' },
    { value: 'SCHEDULE_PUBLISH', label: 'Scheduled For Publish' },
    { value: 'DEPUBLISHED', label: 'De Publish' },
    { value: 'RIGHTS_EXPIRED', label: 'Rights Expired' },
  ],
  ASSET_STATUS: {
    DRAFT: 'DRAFT',
    DRAFT_COMPLETE: 'DRAFT_COMPLETE',
    DRAFT_UNDER_QC: 'DRAFT_UNDER_QC',
    DRAFT_READY_FOR_FEATURING: 'READY_FOR_FEATURING',
    FEATURED: 'featured',
    SCHEDULE_FEATURE: 'SCHEDULE_FEATURE',
    DE_PUBLISH: 'DE_PUBLISH',
    UNFEATURED: 'UNFEATURED',
    RIGHTS_EXPIRED: 'RIGHTS_EXPIRED',
    CREATED: 'CREATED',
    FAILED: 'FAILED',
    ARCHIEVED: 'ARCHIEVED',
    PUBLISH_FAIL: 'PUBLISH_FAIL',
    DRAFT_CREATE: 'DRAFT CREATE',
    PUBLISHED: 'PUBLISHED',
    READY_FOR_PUBLISHING: 'ready_for_featuring',
    SCHEDULE_TO_FEATURE: 'scheduled_to_feature',
    DELETED: 'DELETED',
  },
  COUNT_STATUS: {
    DRAFT: 'draft',
    READY_FOR_PUBLISHING: 'ready_for_featuring',
    FEATURED: 'featured',
    UNFEATURED: 'unfeatured',
    SCHEDULE_FEATURE: 'scheduled_to_feature',
    ARCHIEVED: 'archieved',
    SCHEDULE_TO_FEATURE: 'scheduled_to_feature',
  },
  EVENT_TYPE_STATE: {
    TRANSITION: 'STATE_TRANSITION',
    TRANSITION_IMMEDIATE_PUBLISH: 'STATE_TRANSITION_IMMEDIATE_PUBLISH',
  },
  ASSET_CATEGORY: [
    {
      label: 'GOB',
      value: 'GOB',
    },
    {
      label: 'Bundle',
      value: 'BUNDLE',
    },
    {
      label: 'Content Item',
      value: 'CONTENT_ITEM',
    },
  ],
  ASSET_GROUP_FILE_TYPE: {
    FOLDER: 'Folder',
    FILE: 'File',
  },

  INITIATED_STATUS: ['VERIFICATION_INITIATED', 'PREVIEW_INITIATED', 'TRANSCODING_INITIATED'],
  AFTER_VALIDATION_TYPE: ['preview', 'transcoding'],
  NEED_LONGPULL_STATUS: [
    'CREATE',
    'UPDATE',
    'VERIFICATION_INITIATED',
    'PREVIEW_INITIATED',
    'TRANSCODING_INITIATED',
  ],

  SYSTEM_TOLLGATES: {
    RIGHTS_AND_AVAILABILITY: false,
    MISCELLANEOUS_METADATA: false,
    CONTENT_MEDIA_IMAGE: false,
    DESCRIPTIVE_METADATA: false,
    SEO_METADATA: false,
    BUSINESS_RULES: false,
    CONTENT_MEDIA_VIDEO: false,
    SPORTS_METADATA: false,
    ADVERTISEMENT_METADATA: false,
    TECHNICAL_METADATA: false,
    QC_PROCESS: false,
    PUBLISHING: false,
    RIGHTS_EXPIRED: false,
    DEPUBLISH: false,
    CHECKLIST: false,
  },
  ASSET_DEFINITION_CONFIRMATION_DIALOG: {
    TITLE: 'Confirm',
    DESC: 'Are you sure you want to “Submit” changes made?',
  },
  PARAMETER_VALIDATION_ADDEDD: 'Please submit the added validations to apply.',
  // eslint-disable-next-line quotes
  VIDEO_LANGUAGE_ERROR: "Choose language to enable the 'Fetch & Validate' option.",

  VIDEO_COMPONENT_MESSAGES: {
    MEDIA_PROFILE_UPDATE: 'Media profile updated successfully!',
    FILE_ADD: 'File added successfully!',
    FILE_UPDATE: 'File updated successfully!',
    FILE_REMOVE: 'File removed successfully!',
    LANGUAGE_UPDATE: 'Language updated successfully!',
    SELECT_SUBTITLE: 'Please select language for subtitle.',
  },

  VIDEO_TRANSCODE_STAGES: {
    VALIDATTION: 'validation',
    PREVIEW: 'preview',
    TRANSCODING: 'transcoding',
  },
  CAST_AND_CREW: 'castAndCrew',
  LIVE_OPTIONS: ['LIVE_CHANNEL', 'LIVE_EVENT', 'LIVE_SPORT'],

  PARAMETER_FIELD_TYPE: [
    { value: 'CHECKBOX', label: 'CHECKBOX' },
    { value: 'COMPLEXTYPE', label: 'COMPLEX TYPE' },
    { value: 'DATE', label: 'DATE' },
    { value: 'DATETIME', label: 'DATETIME' },
    { value: 'FILE', label: 'FILE' },
    { value: 'NUMBER', label: 'NUMBER' },
    { value: 'SELECT', label: 'SELECT' },
    { value: 'RADIO', label: 'RADIO' },
    { value: 'TEXT', label: 'TEXT' },
    { value: 'TEXTAREA', label: 'TEXTAREA' },
    { value: 'TIME', label: 'TIME' },
    { value: 'YEAR', label: 'YEAR' },
  ],
  PARAMETER_DATA_TYPE: [
    { value: 'BOOLEAN', label: 'BOOLEAN' },
    { value: 'IMAGE_TYPE', label: 'IMAGE_TYPE' },
    { value: 'DATE', label: 'DATE' },
    { value: 'JSON', label: 'JSON' },
    { value: 'FILE', label: 'FILE' },
    { value: 'NUMBER', label: 'NUMBER' },
    { value: 'STRING', label: 'STRING' },
  ],
  CREATE_PARAMETER_TIPS: [
    'Be Cautious, when you select "Skip Validation," you are granting users the ability to override system validations.',
    'Role names should be distinct and not match any existing role names.',
    'By default, roles are set to "Active." You can modify the status in the "Edit Role" section.',
    'You can make changes to role settings and configurations later.',
  ],
  CHANGE_PARAMETER_STATUS: 'Change Parameter Status',
  EXCLUDED_ASSET_MODAL_CONTAINER: ['externally managed page', 'externally managed pages'],
  AUTOSAVE_CONTAINER_LAYOUTS: [
    'default detail page',
    'default detail pages',
    'content detail page',
    'content detail pages',
  ],
  WIDGET_PAGE_ADD_BUTTON_HIDE: [
    'si fixture',
    'si standing',
    'card cutout layout',
    'sony sport fixture',
    'sony sport standalone highlight widget',
    'sony sport standing',
    'si_score',
    'floating button general',
    'subscription promotion',
    'age_gender_intervention',
  ],
  WIDGET_ASSET_NON_MANDATORY: ['si_ao_score layout'],
  WIDGET_PAGE_ADD_BUTTON_DISABLE: [
    { name: 'card skinned video', length: 1 },
    { name: 'special landscape layout', length: 1 },
    { name: 'special portrait layout', length: 1 },
  ],
  INCLUDED_ASSET_MODAL_BUTTON: ['content detail page'],
  DISABLE_ASSET_WIDGET_BUTTON: ['content detail page'],
  EXCLUDED_NO_ASSET_IMAGE: ['content listing page', 'content listing pages'],
  EXCLUDE_LAYOUTS_PARENT_RIGHT_DRAWER: [
    'default listing page',
    'default listing pages',
    'content listing page',
    'content listing pages',
  ],
  CONTAINER_LAYOUT_HEADER_INFO: {
    'content detail page': DETAIL_HEADER_INFO,
    'content detail pages': DETAIL_HEADER_INFO,
    'default detail page': DETAIL_HEADER_INFO,
    'default detail pages': DETAIL_HEADER_INFO,
    'default listing page': LISTING_HEADER_INFO,
    'default listing pages': LISTING_HEADER_INFO,
    'other layout': CONTAINER_HEADER_INFO,
  },
  INCLUDE_PAGES_CHILD_RIGHT_DRAWER: ['pages', 'page'],
  INCLUDE_LAYOUTS_CHILD_RIGHT_DRAWER: ['content listing page', 'content listing pages'],
  SHOW_ASSET_TYPE_DROPDOWN: [
    { page: 'content detail page', multiSelect: false },
    { page: 'content detail pages', multiSelect: false },
    { page: 'default detail page', multiSelect: true },
    { page: 'default detail pages', multiSelect: true },
  ],
  DISABLE_DELETE_BUTTON: [
    'default listing page',
    'default listing pages',
    'featured',
    'draft create',
    'scheduled to feature',
  ],
  PARAMETER_LISTING_COLUMNS: [
    { id: 'parameterName', label: 'Parameter Name', sort: true },
    { id: 'fieldType', label: 'Field Type', sort: true },
    { id: 'dataType', label: 'Data Type', sort: true },
    { id: 'defaultValue', label: 'Default Value', sort: true },
    { id: 'status', label: 'Status', sort: true },
  ],
  IS_NON_EDITABLE_FIELDS: (fieldName: string) =>
    ['fieldName', 'fieldType', 'dataType', 'esField', 'status', 'multiSelect'].includes(fieldName),

  DELETE_PARAMETER_TITLE: 'Delete Parameter',
  STATUS_ACTIVE_PARAMETER: 'Activate Parameter',
  DELETE_SEGMENT: 'Delete Segment',

  SEGMENT_LISTING_COLUMNS: [
    { id: 'segmentName', label: 'Segment Title', sort: true },
    { id: 'segmentId', label: 'Segment ID', sort: true },
    { id: 'description', label: 'Description', sort: true },
    { id: 'countries', label: 'Country', sort: false },
    { id: 'cluster', label: 'Cluster', sort: false },
    { id: 'packName', label: 'Pack Name', sort: false },
    { id: 'packFamily', label: 'Pack Family', sort: false },
    { id: 'platformList', label: 'Platform', sort: false },
    { id: 'minimumVersion', label: 'Minimum Version', sort: false },
    { id: 'maximumVersion', label: 'Maximum Version', sort: false },
    { id: 'selectVersion', label: 'Selected Version', sort: false },
    { id: 'createdAt', label: 'Creation Date', sort: true },
    { id: 'updatedAt', label: 'Last Updated Date', sort: true },
  ],
  SAVED_SEARCH_LISTING_COLUMNS: [
    { id: 'name', label: 'Search Name', sort: true },
    { id: 'isPinnedToDashboard', label: 'Pinned', sort: true },
    { id: 'action', label: 'Action', sort: false },
  ],
  CLUSTER_OPTIONS: [
    { value: 'CLUSTER01', label: 'Cluster 01', type: 'cluster-01' },
    { value: 'CLUSTER02', label: 'Cluster 02', type: 'cluster-02' },
  ],

  CLONE_OPTIONS: [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '2' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '10', value: '10' },
  ],

  WIDGET_MODAL_TABLE: [
    { id: 'checkbox', label: ' ', sort: false },
    { id: 'assetId', label: 'ID', sort: true },
    { id: 'title', label: 'Title', sort: true },
    { id: 'type', label: 'Type', sort: true },
    { id: 'assetCategory', label: 'Asset Category', sort: true },
    // { id: 'contentCategory', label: 'Content Category', sort: true },
  ],
  SELECTED_WIDGET_MODAL_TABLE: [
    { id: 'assetId', label: 'ID', sort: true },
    { id: 'title', label: 'Title', sort: true },
    { id: 'assetType', label: 'Type', sort: true },
    { id: 'assetCategory', label: 'Asset Category', sort: true },
    // { id: 'contentCategory', label: 'Content Category', sort: true },
  ],
  SELECTED_WIDGET_MODAL_TABLE_FOR_SPOTLIGHT: [
    { id: 'id', label: 'Item ID', sort: true },
    { id: 'title', label: 'Item Title', sort: true },
    { id: 'type', label: 'Item Type', sort: true },
  ],

  CONTAINER_MODAL_TABLE: [
    { id: 'checkbox', label: ' ', sort: false },
    { id: 'widgetId', label: 'ID', sort: true },
    { id: 'title', label: 'Title', sort: true },
    { id: 'type', label: 'Type', sort: true },
    { id: 'layout', label: 'Layout', sort: true },
  ],
  SITE_NAVIGATION_SELECTED_ITEM_WIDGET_MODAL_TABLE: [
    { id: 'widgetId', label: 'ID', sort: false },
    { id: 'widgetTitle', label: 'Title', sort: false },
    { id: 'type', label: 'Type', sort: false },
    { id: 'layout', label: 'Layout', sort: false },
  ],
  SITE_NAVIGATION_SELECTED_ITEM_CONTAINER_MODAL_TABLE: [
    { id: 'containerId', label: 'ID', sort: false },
    { id: 'containerTitle', label: 'Title', sort: false },
    { id: 'type', label: 'Type', sort: false },
    { id: 'layout', label: 'Layout', sort: false },
  ],
  CONTAINER_MODAL_TABLE_FOR_SPOTLIGHT: [
    { id: 'checkbox', label: ' ', sort: false },
    { id: 'widgetId', label: 'ID', sort: true },
    { id: 'title', label: 'Title', sort: true },
    { id: 'type', label: 'Type', sort: true },
    { id: 'layout', label: 'Layout', sort: true },
  ],
  SELECTED_CONTAINER_MODAL_TABLE: [
    { id: 'widgetId', label: 'ID', sort: true },
    { id: 'widgetTitle', label: 'Title', sort: true },
    { id: 'type', label: 'Type', sort: true },
    { id: 'layout', label: 'Layout', sort: true },
  ],
  GLOBAL_SEARCH_LISTING_COLUMNS: [
    { id: 'contentId', label: 'ID', sort: true },
    { id: 'title', label: 'Title', sort: true },
    { id: 'type', label: 'Type', sort: true },
    { id: 'layout', label: 'Layout', sort: true },
    { id: 'state', label: 'State', sort: true },
    { id: 'segment', label: 'Segment', sort: false },
    { id: 'createdAt', label: 'Creation Date', sort: true },
    { id: 'updatedAt', label: 'Last Updated Date', sort: true },
    { id: 'lastFeaturedDate', label: 'Last Featured Date', sort: true },
  ],
  WIDGET_TYPES: {
    cardCutoutLayout: 'card cutout layout',
    spotlightLayout: 'spotlight layout',
    cardSkinnedVideo: 'card skinned video',
    celebrityLayout: 'celebrity layout',
    categoryPageLayout: 'category page layout',
    filteBasedLayoutLandscape: 'filter based layout - landscape',
    filteBasedLayoutPotrait: 'filter based layout - portrait',
    filteBasedLayoutSquare: 'filter based layout - square',
    siKeyMomentsTabView: 'si keymoment tab view layout',
    tabLayout: 'tab_layout',
    specialLandscapeLayout: 'special landscape layout',
    specialPortraitLayout: 'special portrait layout',
  },
  AUDIT_LOGS_LISTING_COLUMNS: [
    { id: 'event', label: 'Event', sort: true },
    { id: 'activity', label: 'Activity', sort: false },
    { id: 'versionId', label: 'Version ID', sort: false },
    { id: 'state', label: 'State', sort: true },
    { id: 'userName', label: 'User Name ', sort: true },
    { id: 'roleName', label: 'Role Name', sort: true },
    { id: 'createdAt', label: 'Date & Time', sort: true },
  ],
  AUDIT_LOGS_FETURED_COLUMNS: [
    { id: 'lastFeaturedDate', label: 'Last Featured On', sort: false },
    // { id: 'download', label: 'Action', sort: false },
  ],
  AUDIT_ITEM_LOGS_LISTING_COLUMNS: [
    { id: 'subType', label: 'Type', sort: true, dataType: 'String', showFilter: false },
    { id: 'contentId', label: 'Content ID', sort: true, dataType: 'String', showFilter: false },
    { id: 'title', label: 'Title', sort: true, dataType: 'String', showFilter: true },
    { id: 'event', label: 'Event', sort: true, dataType: 'String', showFilter: true },
    { id: 'state', label: 'State', sort: true, dataType: 'String', showFilter: false },
    { id: 'activity', label: 'Activity', sort: false, dataType: 'String', showFilter: true },
    { id: 'layout', label: 'Layout', sort: true, dataType: 'String', showFilter: false },
    { id: 'versionId', label: 'Version ID', sort: false, dataType: 'String', showFilter: true },
    { id: 'userName', label: 'User Name ', sort: true, dataType: 'String', showFilter: true },
    { id: 'roleName', label: 'Role Name', sort: true, dataType: 'String', showFilter: true },
    { id: 'createdAt', label: 'Date & Time', sort: true, dataType: 'Date', showFilter: true },
  ],

  MANAGE_TIPS: [
    // { id: 'id', label: 'ID', sort: false },
    { id: 'module', label: 'Module', sort: false },
    { id: 'page', label: 'Page', sort: false },
    { id: 'createdAt', label: 'Creation Date', sort: true },
    { id: 'updatedAt', label: 'Last Updation Date', sort: true },
  ],

  DELETE_TIP: 'Delete Tip',

  // TIPS_KEY : [
  //   {
  //     key:'widget_presentation_view', value:'widget Presentation view',
  //   },
  //   {
  //     key:'widget_data_view', value:'widget Data view',
  //   },
  // ]

  TIPS_KEY: [
    { key: 'widget_data_add', value: 'WIDGET DATA CREATE' },
    { key: 'widget_data_edit', value: 'WIDGET DATA EDIT' },
    { key: 'widget_data_view', value: 'WIDGET DATA VIEW' },
    // { key: 'widget_data_edit', value: 'WIDGET DATA CREATE' },

    { key: 'widget_presentation_add', value: 'WIDGET PRESENTATION CREATE' },
    { key: 'widget_presentation_edit', value: 'WIDGET PRESENTATION EDIT' },
    { key: 'widget_presentation_view', value: 'WIDGET PRESENTATION VIEW' },
    // { key: 'widget_presentation_edit', value: 'WIDGET PRESENTATION CREATE' },

    { key: 'widget_behaviour_add', value: 'WIDGET BEHAVIOUR CREATE' },
    { key: 'widget_behaviour_edit', value: 'WIDGET BEHAVIOUR EDIT' },
    { key: 'widget_behaviour_view', value: 'WIDGET BEHAVIOUR VIEW' },
    // { key: 'widget_behaviour_edit', value: 'WIDGET BEHAVIOUR CREATE' },

    { key: 'widget_checklist_add', value: 'WIDGET CHECKLIST CREATE' },
    { key: 'widget_checklist_edit', value: 'WIDGET CHECKLIST EDIT' },
    { key: 'widget_checklist_view', value: 'WIDGET CHECKLIST VIEW' },
    // { key: 'widget_checklist_edit', value: 'WIDGET CHECKLIST CREATE' },

    { key: 'container_data_add', value: 'CONTAINER DATA CREATE' },
    { key: 'container_data_edit', value: 'CONTAINER DATA EDIT' },
    { key: 'container_data_view', value: 'CONTAINER DATA VIEW' },
    // { key: 'container_data_edit', value: 'WIDGET CHECKLIST CREATE' },

    { key: 'container_presentation_add', value: 'CONTAINER PRESENTATION CREATE' },
    { key: 'container_presentation_edit', value: 'CONTAINER PRESENTATION EDIT' },
    { key: 'container_presentation_view', value: 'CONTAINER PRESENTATION VIEW' },
    // { key: 'container_presentation_edit', value: 'CONTAINER PRESENTATION CREATE' },

    { key: 'container_behaviour_add', value: 'CONTAINER BEHAVIOUR CREATE' },
    { key: 'container_behaviour_edit', value: 'CONTAINER BEHAVIOUR EDIT' },
    { key: 'container_behaviour_view', value: 'CONTAINER BEHAVIOUR VIEW' },
    // { key: 'container_behaviour_edit', value: 'CONTAINER BEHAVIOUR CREATE' },

    { key: 'container_checklist_add', value: 'CONTAINER CHECKLIST CREATE' },
    { key: 'container_checklist_edit', value: 'CONTAINER CHECKLIST EDIT' },
    { key: 'container_checklist_view', value: 'CONTAINER CHECKLIST VIEW' },
    // { key: 'container_checklist_edit', value: 'CONTAINER CHECKLIST CREATE' },

    { key: 'site_navigation_data_add', value: 'SITE_NAVIGATION DATA CREATE' },
    { key: 'site_navigation_data_edit', value: 'SITE_NAVIGATION DATA EDIT' },
    { key: 'site_navigation_data_view', value: 'SITE_NAVIGATION DATA VIEW' },
    // { key: 'site_navigation_data_edit', value: 'SITE_NAVIGATION DATA CREATE' },

    { key: 'site_navigation_presentation_add', value: 'SITE_NAVIGATION PRESENTATION CREATE' },
    { key: 'site_navigation_presentation_edit', value: 'SITE_NAVIGATION PRESENTATION EDIT' },
    { key: 'site_navigation_presentation_view', value: 'SITE_NAVIGATION PRESENTATION VIEW' },
    // { key: 'site_navigation_presentation_edit', value: 'SITE_NAVIGATION PRESENTATION CREATE' },

    { key: 'site_navigation_behaviour_add', value: 'SITE_NAVIGATION BEHAVIOUR CREATE' },
    { key: 'site_navigation_behaviour_edit', value: 'SITE_NAVIGATION BEHAVIOUR EDIT' },
    { key: 'site_navigation_behaviour_view', value: 'SITE_NAVIGATION BEHAVIOUR VIEW' },
    // { key: 'site_navigation_behaviour_edit', value: 'SITE_NAVIGATION BEHAVIOUR CREATE' },

    { key: 'site_navigation_checklist_add', value: 'SITE_NAVIGATION CHECKLIST CREATE' },
    { key: 'site_navigation_checklist_edit', value: 'SITE_NAVIGATION CHECKLIST EDIT' },
    { key: 'site_navigation_checklist_view', value: 'SITE_NAVIGATION CHECKLIST VIEW' },
    // { key: 'site_navigation_checklist_edit', value: 'SITE_NAVIGATION CHECKLIST CREATE' },

    { key: 'segment_add', value: 'SEGMENT CREATE' },
    { key: 'segment_edit', value: 'SEGMENT EDIT' },
    { key: 'segment_view', value: 'SEGMENT VIEW' },
    // { key: 'segment_edit', value: 'SEGMENT CREATE' },

    { key: 'role_management_add', value: 'ROLE_MANAGEMENT CREATE' },
    { key: 'role_management_edit', value: 'ROLE_MANAGEMENT EDIT' },
    { key: 'role_management_view', value: 'ROLE_MANAGEMENT VIEW' },
    // { key: 'role_management_edit', value: 'ROLE_MANAGEMENT CREATE' },

    { key: 'user_management_add', value: 'USER_MANAGEMENT CREATE' },
    { key: 'user_management_edit', value: 'USER_MANAGEMENT EDIT' },
    { key: 'user_management_view', value: 'USER_MANAGEMENT VIEW' },
    // { key: 'user_management_edit', value: 'USER_MANAGEMENT CREATE' },

    { key: 'parameter_repository_add', value: 'PARAMETER_REPOSITORY CREATE' },
    { key: 'parameter_repository_edit', value: 'PARAMETER_REPOSITORY EDIT' },
    { key: 'parameter_repository_view', value: 'PARAMETER_REPOSITORY VIEW' },
    // { key: 'parameter_repository_edit', value: 'PARAMETER_REPOSITORY CREATE' },

    { key: 'widget_definition_add', value: 'WIDGET_DEFINITION CREATE' },
    { key: 'widget_definition_edit', value: 'WIDGET_DEFINITION EDIT' },
    { key: 'widget_definition_view', value: 'WIDGET_DEFINITION VIEW' },
    // { key: 'widget_definition_edit', value: 'WIDGET_DEFINITION CREATE' },

    { key: 'container_definition_add', value: 'CONTAINER_DEFINITION CREATE' },
    { key: 'container_definition_edit', value: 'CONTAINER_DEFINITION EDIT' },
    { key: 'container_definition_view', value: 'CONTAINER_DEFINITION VIEW' },
    // { key: 'container_definition_edit', value: 'CONTAINER_DEFINITION CREATE' },

    { key: 'master_add', value: 'MASTER CREATE' },
    { key: 'master_edit', value: 'MASTER EDIT' },
    { key: 'master_view', value: 'MASTER VIEW' },
    // { key: 'master_edit', value: 'MASTER CREATE' }
  ],

  LINKED_ITEMS: [{ key: 'Associated items', value: 'Associated Items' }],

  ASSOCIATED_WIDGET_LISTING_COLUMNS: [
    { id: 'widgetTitle', label: 'Title', sort: true, dataType: 'String', showFilter: true },
    { id: 'widgetId', label: 'ID', sort: true, dataType: 'Number', showFilter: true },
    { id: 'type', label: 'Type', sort: true, dataType: 'String', showFilter: true },
    { id: 'layout', label: 'Layout', sort: true, dataType: 'String', showFilter: false },
    { id: 'engine', label: 'Data Engine', sort: true, dataType: 'String', showFilter: true },
    { id: 'state', label: 'State', sort: true, dataType: 'String', showFilter: false },
    { id: 'segment', label: 'Segment', sort: false, dataType: 'String', showFilter: true },
    { id: 'createdAt', label: 'Creation Date', sort: true, dataType: 'Date', showFilter: true },
    { id: 'updatedAt', label: 'Last Update Date', sort: true, dataType: 'Date', showFilter: true },
    {
      id: 'lastFeaturedDate',
      label: 'Last Featured Date',
      sort: true,
      dataType: 'Date',
      showFilter: true,
    },
  ],
  ASSOCIATED_CONTAINER_LISTING_COLUMNS: [
    { id: 'containerTitle', label: 'Title', sort: true, dataType: 'String', showFilter: true },
    { id: 'containerId', label: 'ID', sort: true, dataType: 'Number', showFilter: true },
    { id: 'type', label: 'Type', sort: true, dataType: 'String', showFilter: true },
    { id: 'layout', label: 'Layout', sort: true, dataType: 'String', showFilter: false },
    { id: 'state', label: 'State', sort: true, dataType: 'String', showFilter: false },
    { id: 'segment', label: 'Segment', sort: false, dataType: 'String', showFilter: true },
    { id: 'language', label: 'Language', sort: true, dataType: 'String', showFilter: true },
    { id: 'createdAt', label: 'Creation Date', sort: true, dataType: 'Date', showFilter: true },
    { id: 'updatedAt', label: 'Last Update Date', sort: true, dataType: 'Date', showFilter: true },
    {
      id: 'lastFeaturedDate',
      label: 'Last Featured Date',
      sort: true,
      dataType: 'Date',
      showFilter: true,
    },
  ],

  ASSOCIATED_WIDGET_HEADER: [
    { key: 'Segment', value: 'segment' },
    { key: 'Data Engine', value: 'dataEngine' },
    { key: 'Widget Id', value: 'widgetId' },
    { key: 'Created on', value: 'createdAt' },
    { key: 'Last Updated on', value: 'updatedAt' },
    { key: 'Last Featured on', value: 'lastFeaturedDate' },
  ],

  ASSOCIATED_CONTAINER_HEADER: [
    { key: 'Container ID', value: 'containerId' },
    { key: 'Segment', value: 'dataEngine' },
    { key: 'Created on', value: 'createdAt' },
    { key: 'Last Updated on', value: 'updatedAt' },
    { key: 'Last Featured On', value: 'widgetId' },
  ],
}
