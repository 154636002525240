import { Box, Grid } from '@mui/material'
import { memo, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import LootieImage from '../../../Assets/Images/dashboardGif.gif'
import { PAGES_ROUTES } from '../../../Constants'
import { HOME_PAGE } from '../../../Constants/ApiDefinations'
import { RoleConfirmationModal } from '../../../Models'
import mainApiService from '../../../Services'
import { showAlert } from '../../../Store/Slice/alert.slice'
import RoleConfirmation from '../../Dialogs/RoleConfirmation'
import MyDashboardIcon from '../../Icons/MyDashboardIcon'
import homePin from '../../../Assets/Images/homePin.svg'
import './Dashboard.scss'
import { useAppDispatch } from '../../../Store/Hooks'
import { HOME_PAGE_TEXT } from '../../../Constants/Texts'

const Dashboard = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [pinSearchData, setPinSearchData] = useState<any[]>([])
  const [dialogProps, setDialogProps] = useState<RoleConfirmationModal>({
    heading: '',
    description: '',
    color: 'primary',
    open: false,
  })
  const [selectedPinnedId, setSelectedPinnedId] = useState('')

  // Fetch pinned search data
  useEffect(() => {
    const fetchPinnedSearch = async () => {
      try {
        const requestedData = HOME_PAGE.GET_DASHBOARD_PIN_SEARCH_DATA()
        const result = await mainApiService(requestedData)
        if (result?.responseCode === 200) {
          setPinSearchData(result?.data || [])
          return
        }
        if (result?.error?.responseCode === 404) {
          return
        } else {
          dispatch(showAlert([true, result?.error?.message, 'error']))
        }
      } catch (error) {
        console.error('Error fetching pinned search data:', error)
      }
    }

    fetchPinnedSearch()
  }, [])

  const handleClose = () => {
    setDialogProps({
      heading: '',
      description: '',
      color: 'primary',
      open: false,
    })
    setSelectedPinnedId('')
  }

  const handleUnpinClick = async () => {
    const payload = {
      _id: selectedPinnedId,
      isPinnedToDashboard: false,
    }
    try {
      const requestedData = HOME_PAGE.UNPIN_SAVE_DATA(payload)
      const result = await mainApiService(requestedData)

      if (result?.responseCode === 200) {
        handleClose()
        dispatch(showAlert([true, result?.message, 'success']))
        const updatedData = pinSearchData.filter((item) => item._id !== selectedPinnedId)
        setPinSearchData(updatedData)
        return
      } else {
        dispatch(showAlert([true, result?.error?.message, 'error']))
      }
    } catch (error) {
      console.error('Error unpinning:', error)
    }
  }

  const openDialog = (name: string, id: string) => {
    setSelectedPinnedId(id)
    setDialogProps({
      heading: 'Unpin Search',
      description: (
        <div>
          <p>Are you sure you want to unpin this search?</p>
        </div>
      ),
      color: 'warning',
      Icon: 'DeleteRoleWarIcon',
      open: true,
      iconColor: 'warning',
      cancelButtonLabel: 'Cancel',
      successButtonLabel: 'Unpin',
      handleClose: handleClose,
    })
  }

  return pinSearchData.length > 0 ? (
    <div className='card-wrapper'>
      <h3 className='dashboard-header pb-1'>
        <MyDashboardIcon />
        {HOME_PAGE_TEXT.DASHBOARD.HEADER_TITLE}
      </h3>
      <Grid container spacing={2} className='mb-1 mt-0'>
        {pinSearchData.map((item, index) => (
          <Grid item xs={4} key={item._id} className='cursor-pointer card-padding'>
            <div
              className={`dashboard-card color${index}`}
              onClick={() => {
                navigate(`/${PAGES_ROUTES.AdvancedSearch}/${item?._id}`)
              }}
              data-testId={`pinned-card-${index}`}
            >
              <Box className='sub-heading-5 py-4 title d-flex justify-content-between align-items-center'>
                {item.name}
                <div
                  data-testid={`pinnedId-${index}`}
                  className='icon'
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    openDialog(item.name, item._id)
                  }}
                >
                  <img data-testId={`pinnedId-${index}}`} src={homePin} alt='Pin Icon' />
                </div>
              </Box>
              <p className='heading-2 py-4'>{item.recordCount}</p>
            </div>
          </Grid>
        ))}
      </Grid>
      <RoleConfirmation
        handleSuccess={handleUnpinClick}
        {...dialogProps}
        handleClose={handleClose}
      />
    </div>
  ) : (
    <div className='dashboard-wrapper'>
      <h3>
        <MyDashboardIcon />
        {HOME_PAGE_TEXT.DASHBOARD.HEADER_TITLE}
      </h3>
      <div className='d-flex justify-content-between align-items-center'>
        <img className='ml-auto mr-auto' src={LootieImage} height={238} alt='No Data' />
      </div>
      <div className='lootie-text text-center' data-testid='no-data'>
        Pin your search from &apos;Advanced Search&apos; section
      </div>
    </div>
  )
}

export default memo(Dashboard)
