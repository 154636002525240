import React from 'react'
import ProgressBarStyles from './ProgressBar.module.scss'
import TickIcon from '../../../Assets/Images/svgs/tick-icon'
import CrossIcon from '../../../Assets/Images/svgs/cross-icon'
interface IProgressBarData {
  progressBarData: any
}

const ProgressBar: React.FC<IProgressBarData> = ({ progressBarData }) => {
  const { records, systemNames } = progressBarData

  const getStatusClass = (status: any) => {
    switch (status) {
      case 'success':
        return {
          circleClass: 'circle_success',
          lineClass: 'line_success-line',
          icon: <TickIcon />,
          dotClass: '',
        }
      case 'failure':
        return {
          circleClass: 'circle_error',
          lineClass: 'fade-line',
          icon: <CrossIcon />,
          dotClass: '',
        }
      case 'next_success':
        return {
          circleClass: 'circle_next_success',
          lineClass: '',
          icon: '',
          dotClass: 'dot_next_success_dot',
        }
      case 'next_fail':
        return {
          circleClass: '',
          lineClass: 'line_fade-line',
          icon: '',
          dotClass: '',
        }
      default:
        return { circleClass: 'circle_pending', lineClass: 'pending-line', icon: '', dotClass: '' }
    }
  }

  if (records?.length > 0) {
    systemNames?.forEach((system: any) => {
      const dataItem = records.find((item: any) => item.details.sysName === system.key)
      if (dataItem) {
        system.status = dataItem.status
      }
    })
  }

  const reorderRecords = () => {
    const systemOrder = systemNames?.reduce((acc: any, system: any) => {
      acc[system?.key] = system?.order
      return acc
    }, {})
    return records?.sort((a: any, b: any) => {
      const orderA = systemOrder[a.details.sysName] || Infinity
      const orderB = systemOrder[b.details.sysName] || Infinity
      return orderA - orderB
    })
  }

  return (
    <div className={`${ProgressBarStyles['progress-container']}`}>
      {systemNames
        ?.sort((a: any, b: any) => a.order - b.order)
        ?.map((step: any, index: any) => {
          const reorderedRecords = reorderRecords()

          if (
            reorderedRecords[reorderedRecords.length - 1]?.status == 'failure' &&
            index > reorderedRecords.length - 1
          ) {
            step.status = 'next_fail'
          } else if (
            reorderedRecords[reorderedRecords.length - 1]?.status == 'success' &&
            index === reorderedRecords.length
          ) {
            step.status = 'next_success'
          }
          const { circleClass, lineClass, icon, dotClass } = getStatusClass(step.status)
          return (
            <div key={step?.key} className={`${ProgressBarStyles['progress-step']}`}>
              <div className={` ${ProgressBarStyles['circle']} ${ProgressBarStyles[circleClass]}`}>
                {icon ? (
                  <span className={`${ProgressBarStyles['progressBarIcon']}`}>{icon}</span>
                ) : (
                  <span
                    className={` ${ProgressBarStyles['dot']} ${ProgressBarStyles[dotClass]}`}
                  ></span>
                )}
              </div>
              <span className={`${ProgressBarStyles['label']}`}>{step.value}</span>
              {index < systemNames?.length - 1 && (
                <div
                  className={` ${ProgressBarStyles['line']} ${ProgressBarStyles[lineClass]}`}
                ></div>
              )}
            </div>
          )
        })}
    </div>
  )
}

export default ProgressBar
