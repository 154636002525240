import ActivateRoleIcon from '../../../Assets/Images/activate-role-icon.png'
import ActivateUserIcon from '../../../Assets/Images/activate-user-icon.png'
import ActiveIcon from '../../../Assets/Images/active-icon.png'
import CopyClipboardIcon from '../../../Assets/Images/clone-icon.png'
import DeactivateRoleIcon from '../../../Assets/Images/deactivate-role-icon.png'
import DeactivateUserIcon from '../../../Assets/Images/delete-deactivate-user-icon.png'
import DeleteRoleIcon from '../../../Assets/Images/delete-role-icon.png'
import DeleteRoleWarIcon from '../../../Assets/Images/delete-role-warn-icon.png'
import DialogEditIcon from '../../../Assets/Images/dialog-edit-icon.svg'
import DialogViewIcon from '../../../Assets/Images/dialog-view-icon.svg'
import AssetLinkGridWarnIcon from '../../../Assets/Images/featured-icon-red.png'
import AssetLinkGridIcon from '../../../Assets/Images/featured-icon.png'
import GreentickIcon from '../../../Assets/Images/gren-tick-icon.png'
import InactiveIcon from '../../../Assets/Images/inactive-icon.png'
import { FeaturedIcon, UnFeaturedIcon } from '../../Icons'
import PinIcon from '../../../Assets/Images/pinIcon.png'
import FeaturedTabIcon from '../../../Assets/Images/FeaturedIcon.png'

interface IProps {
  IconName: any
}

const getIconSrc = (IconName: string) => {
  switch (IconName) {
    case 'ActivateRoleIcon':
      return ActivateRoleIcon
    case 'DeactivateRoleIcon':
      return DeactivateRoleIcon
    case 'DeleteRoleIcon':
      return DeleteRoleIcon
    case 'DeactivateUserIcon':
      return DeactivateUserIcon
    case 'DeleteRoleWarIcon':
      return DeleteRoleWarIcon
    case 'ActivateUserIcon':
      return ActivateUserIcon
    case 'ActiveIcon':
      return ActiveIcon
    case 'InactiveIcon':
      return InactiveIcon
    case 'AssetGridIcon':
      return AssetLinkGridIcon
    case 'AssetGridWarnIcon':
      return AssetLinkGridWarnIcon
    case 'CopyClipboardIcon':
      return CopyClipboardIcon
    case 'GreentickIcon':
      return GreentickIcon
    case 'DialogEditIcon':
      return DialogEditIcon
    case 'DialogViewIcon':
      return DialogViewIcon
    case 'PinIcon':
      return PinIcon
    case 'FeaturedTabIcon':
      return FeaturedTabIcon
    default:
      return ''
  }
}

const ModalIcon = ({ IconName }: IProps) => {
  if (IconName === 'FeaturedIcon') {
    return <FeaturedIcon />
  }

  if (IconName === 'UnFeaturedIcon') {
    return <UnFeaturedIcon />
  }

  const iconSrc = getIconSrc(IconName)
  return iconSrc ? <img src={iconSrc} alt={IconName} /> : null
}

export default ModalIcon
